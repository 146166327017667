:root{
  --skills-font-family: "Climate Crisis", sans-serif;
  --skills-font-size-pc:50px;
  --skills-font-size-tablet:40px;
  --skills-font-size-mobile:40px;


  --tooltip-bg-color: rgba(31, 22, 83, 0.7);     
  --tooltip-bg-colornav: rgb(0, 0, 0);    
  --tooltip-text-color: #ffffff;  
  --tooltip-border-color: transparent;
  --tooltip-font-family: "Lexend Deca", sans-serif; 
  --tooltip-font-size-pc: 15px;    
  --tooltip-font-size-tablet: 13px;  
  --tooltip-font-size-mobile: 12px;  
  --tooltip-padding: 5px;         
  --tooltip-border-radius: 1px;   
  --tooltip-max-width: 250px;      
  --tooltip-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);


  --skillstext-font-family:  "Lexend Deca", sans-serif; 
  --skillstext-font-size-pc:15px;
  --skillstext-font-size-tablet:15px;
  --skillstext-font-size-mobile:15px;
  --skillstext-bg-color:rgb(0, 0, 0,.5);
  --skillstext-bg-color-2:rgb(0, 0, 0,.5);
  --skillstext-font-color:white;
  --skillstext-border:2px solid white;
  --skillstext-border-2:5px solid rebeccapurple;


  --headd-font-size-pc:50px;
  --headd-font-size-tablet:40px;
  --headd-font-size-mobile:40px;
  --headd-font-family:"Lexend Deca", sans-serif; 



   --skill-text-bg:  radial-gradient(circle at top left, rgb(78, 0, 0,.7), transparent 50%),
radial-gradient(circle at top right, rgb(0, 5, 49,.7), transparent 50%),
radial-gradient(circle at bottom left, rgb(0, 5, 49,.7), transparent 50%),
radial-gradient(circle at bottom right, rgb(78, 0, 0,.7), transparent 50%),
radial-gradient(circle, rgb(102, 51, 153,.2), transparent 100%);
  --skills-main-bg:linear-gradient(to bottom, rgb(39, 30, 110),rgb(0, 0, 0));
 

}


.retro-mode-enable{

  --skillstext-bg-color:white;
  --skillstext-bg-color-2:white;
  --skillstext-font-color:black;
  --skillstext-border:3px solid rgb(0, 0, 0);


  --skills-main-bg:linear-gradient(to bottom, rgb(165, 228, 247),rgb(188, 179, 255));
  --skill-text-bg:  white;
}









/* Main tooltip container */
.custom-tooltip {
  background-color: var(--tooltip-bg-color) !important;
  color: var(--tooltip-text-color) !important;
  border: 1px solid var(--tooltip-border-color) !important;
  padding: var(--tooltip-padding) !important;
  font-family: var(--tooltip-font-family) !important;
  font-size: var(--tooltip-font-size-pc) !important;
  border-radius: var(--tooltip-border-radius) !important;
  max-width: var(--tooltip-max-width) !important;
  text-align: center;
  box-shadow: var(--tooltip-box-shadow) !important;
  font-weight: 200 !important;
  transition: all 0.3s ease;
}

.custom-tooltipnav {
  background-color: var(--tooltip-bg-colornav) !important;
  color: var(--tooltip-text-color) !important;
  border: 1px solid var(--tooltip-border-color) !important;
  padding: var(--tooltip-padding) !important;
  font-family: var(--tooltip-font-family) !important;
  font-size: var(--tooltip-font-size-pc) !important;
  border-radius: var(--tooltip-border-radius) !important;
  max-width: var(--tooltip-max-width) !important;
  text-align: center;
  box-shadow: var(--tooltip-box-shadow) !important;
  font-weight: 200 !important;
  transition: all 0.3s ease;
}

/* Ensure multiline tooltips render well */
.custom-tooltip[data-multiline='true'] {
  white-space: pre-wrap; /* Preserve line breaks */
}




.read-more4 {
  background: transparent;
  border: 1px solid black;
  color: black;
  font-family: var(--prof-name-font-family);
  background-color: white;
  font-size: 16px;
  cursor: pointer;
  padding: 8px;
  border-radius: 5px;
  margin-top: 15px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
}

.read-more4:hover {
  background-color: rgb(212, 212, 212);
}

.read-more4:active {
  transform: scale(0.9);
}


.skillstextheader{
  font-family: var(--headd-font-family);
  font-size: var(--headd-font-size-pc);
  font-weight: 100;
  margin: 0;
}

.Skillstext {
  position: relative;
  font-family: var(--skillstext-font-family);
  font-size: var(--skillstext-font-size-pc);
  font-weight: 300;
  padding: 20px;
  text-align: start;
  line-height: 2;
  word-wrap: break-word; 
  margin: 50px auto; 
  max-width: 40vw;
  color: var(--skillstext-font-color);
  background:var(--skill-text-bg);
  border: var(--skillstext-border);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.1s ease;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);
}

.Skillstext-content {
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}








.skills-main{
  background: var(--skills-main-bg);
  min-height: 100vh;
  padding-block: 100px;
}


.skillsheader{
  font-family: var(--skills-font-family);
  font-size: var(--skills-font-size-pc);
  font-weight: 100;
  margin-bottom: 50px;
  margin-top: 0%;
  color: var(--skillstext-font-color);
  text-shadow: 1px 5px 10px rgba(0, 0, 0, 0.3);
}


.skills-section {
  position: relative;
  text-align: center;
  background: transparent; /* Transparent background */
  min-height: 100vh;
  overflow: hidden;
  margin: 0;
}

/* Specific styles for each skill */
.react-skill .skill-img {
  background-color: black;
  border: var(--skillstext-border);
}

.javascript-skill .skill-img {
  background-color: rgb(255, 255, 255);
  border: var(--skillstext-border);
}

.python-skill .skill-img {
  background-color: rgb(238, 147, 147);
  border: var(--skillstext-border);

}

.node-skill .skill-img {
  background-color: rgb(255, 255, 255);
  border: var(--skillstext-border);

}

.firebase-skill .skill-img {
  background-color: rgb(245, 226, 143);
  border: var(--skillstext-border);

}

.css-skill .skill-img {
  background-color: rgb(255, 255, 255);
  border: var(--skillstext-border);

}

.html-skill .skill-img {
  background-color: rgb(94, 130, 197);
  border: var(--skillstext-border);

}

.ae-skill .skill-img {
  background-color: rgb(255, 255, 255);
  border: var(--skillstext-border);

}

.mongodb-skill .skill-img {
  background-color: rgb(176, 255, 123);
  border: var(--skillstext-border);

}

.photoshop-skill .skill-img {
  background-color: rgb(255, 255, 255);
  border: var(--skillstext-border);

}

.unsplash-skill .skill-img {
  background-color: rgb(125, 133, 212);
  border: var(--skillstext-border);

}

.illustrator-skill .skill-img {
  background-color: rgb(255, 255, 255);
  border: var(--skillstext-border);

}


.skill-card {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative; /* Creates a stacking context */
  z-index: 1; /* Ensures box shadows are not clipped */
  overflow: visible; /* Allows content within the card to overflow */
}

.skill-img {
  width: 150px;
  height: 150px;
  padding: 10px;
  border-radius: 25px;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);
}



.swiper-slide {
  padding: 20px; /* Adjust for extra space */
}

.skills-section {
  padding: 90px; /* Increased to ensure space for shadows */
}

.skill-card {
  transition: transform 0.5s linear(0, 0.011 0.8%, 0.041 1.6%, 0.158 3.3%, 0.814 9.5%, 1.04 12.1%, 1.123 13.4%, 1.185 14.7%, 1.226 16%, 1.248 17.3%, 1.252 18.9%, 1.231 20.7%, 1.19 22.6%, 1.058 27.4%, 1 29.9%, 0.958 32.6%, 0.938 35.4%, 0.941 39%, 0.999 48.1%, 1.016 53.5%, 0.996 71.3%, 1);
}

.skill-card:hover {
  transform: scale(0.9);
}

.skill-card:active {
  transform: scale(0.95);
}









@media (max-width: 768px) {

  .Skillstext {
    font-size: var(--skillstext-font-size-mobile);
    min-width: 75vw;
  }

  .custom-tooltip {
    font-size: var(--tooltip-font-size-mobile) !important;
  }
  

  .skills-section {
    padding: 0px;
  }

  .skill-card {
    padding: 10px;
  }

  .skill-img {
    max-width: 50px;
    max-height: 50px;
    padding: 10px;
    border-radius: 5px;
  }

  .swiper-slide {
    padding: 5px; /* Adjust for extra space */
  }
}



@media (min-width: 769px) and (max-width: 1024px) {

  .Skillstext {
    font-size: var(--skillstext-font-size-tablet);
    min-width: 65vw;
    margin: 200px auto; 
  }

  .custom-tooltip {
    font-size: var(--tooltip-font-size-tablet) !important;
  }

  .skills-section {
    padding: 0px;
  }

  .skill-card {
    padding: 10px;
  }

  .skill-img {
    width: 120px;
    height: 120px;
    padding: 10px;
    border-radius: 10px;
  }

  .swiper-slide {
    padding: 15px; /* Adjust for extra space */
  }
}
