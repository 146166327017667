:root{
  --welcome-font-family: "Climate Crisis", sans-serif;
  --welcome-font-size-pc:40px;
  --welcome-font-size-tablet:40px;
  --welcome-font-size-mobile:40px;
 
  --yellow:rgb(255, 245, 116);
  --red:rgb(255, 131, 131);
  --blue:rgb(161, 214, 203);
  --purple:rgb(161, 154, 211);

  --about-main-bg:linear-gradient(to bottom, rgb(39, 30, 110),rgb(0, 0, 0));
  --about-font-family: "Lexend Deca", sans-serif; 
  --about-font-size-pc:18px;
  --about-font-size-tablet:18px;
  --about-font-size-mobile:18px;
  --about-bg-color:rgb(0, 0, 0,.5);
  --about-font-color:white;
  --about-border:5px solid rebeccapurple;

  --about-text-bg:  radial-gradient(circle at top left, rgb(78, 0, 0,.7), transparent 50%),
  radial-gradient(circle at top right, rgb(0, 5, 49,.7), transparent 50%),
  radial-gradient(circle at bottom left, rgb(0, 5, 49,.7), transparent 50%),
  radial-gradient(circle at bottom right, rgb(78, 0, 0,.7), transparent 50%),
  radial-gradient(circle, rgb(102, 51, 153,.2), transparent 100%);


  --text-font-family:"Climate Crisis", sans-serif;
  --font-color2:white;
    --font-size2-mobile2:18px;
    --font-size2-tablet2:23px;
    --font-size2:30px;

  --star-width-mobile:30px;
  --astro-width-mobile:100px;
  --planet-width-mobile:100px;

  --star-width-tablet:50px;
  --astro-width-tablet:150px;
  --planet-width-tablet:150px;

  --star-width:70px;
  --astro-width:200px;
  --planet-width:200px;


  --timing: 400ms;
  --timing-2: 600ms;
  --rotation: 25deg;



}

.retro-mode-enable{
  --yellow:rgb(255, 245, 116);
  --red:rgb(255, 131, 131);
  --blue:rgb(161, 214, 203);
  --purple:rgb(161, 154, 211);

  --about-bg-color:rgb(0, 0, 0,.5);
  --about-font-color:rgb(0, 0, 0);
  --about-border:3px solid rgb(0, 0, 0);

  --font-color2:white;

  --about-main-bg:linear-gradient(to bottom, rgb(188, 179, 255),rgb(165, 228, 247));
  --about-text-bg: white;
}


.about-main{
  background: var(--about-main-bg);
}



.about-section {
  position: relative;
  text-align: center;
  background: transparent;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: row;
  gap: 10rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}


.abttextdiv{
  display: flex;
  flex-direction: column;
}


.welcometext {
  font-family: var(--welcome-font-family);
  font-size: var(--welcome-font-size-pc);
  font-weight: 100;
  text-shadow: 1px 5px 10px rgba(0, 0, 0, 0.3);
  color: var(--about-font-color);
  letter-spacing: 2px;
}

.cursor {
  display: inline-block;
  font-size: var(--welcome-font-size-pc);
  font-family: var(--welcome-font-family);
  animation: blink 0.8s step-start infinite;
  text-shadow: 1px 5px 2px rgba(0, 0, 0, 0.3);
}

/* Cursor blinking effect */
.cursor.blink {
  visibility: visible;
}

@keyframes blink {
  50% {
    visibility: hidden;
  }
}

.abouttext {
  position: relative;
  font-family: var(--about-font-family);
  font-size: var(--about-font-size-pc);
  font-weight: 300;
  color: var(--about-font-color);
  background: var(--about-text-bg);
  padding: 20px;
  text-align: start;
  line-height: 1.6;
  word-wrap: break-word; 
  margin: 20px auto; 
  max-width: 700px;
  border: var(--about-border);
  z-index: 98;
  border-radius: 20px;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);
}

.sparkle {
  position: absolute;
  top: -52px;
  right: -41.5px;
  height: 100px;
  width: 80px;
  background: var(--yellow);
  mask: radial-gradient(#0000 71%, #000 72%) 10000% 10000%/99.5% 99.5%;
  animation: sparkleEffect 5s infinite ease-in-out;
  z-index: 99;
}

.sparkle::after {
  content: '';
  --b: 2px; 
  position: absolute; 
  top: 0; 
  left: 0; 
  height: 100px;
  width: 80px;
  background: var(--red);
  mask: radial-gradient(#0000 71%, #000 72% calc(72% + var(--b)), #0000 calc(73% + var(--b))) 10000% 10000%/99.5% 99.5%;
  z-index: 100;
}


@keyframes sparkleEffect {
  0% {
    transform: scale(1) rotate(0deg);
  }
  20% {
    transform: scale(1.1) rotate(10deg);
  }
  40% {
    transform: scale(1.15) rotate(-5deg);
  }
  60% {
    transform: scale(1.1) rotate(5deg);
  }
  80% {
    transform: scale(1.12) rotate(-5deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}










.profilecard{
  padding: 00px;
}

.card{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  perspective: 750px;
  transform-origin: bottom;
  cursor: pointer;
  margin: 0;
  padding: 0; 
  position: relative; 
}

.title{
  opacity: 0;
  position: absolute;
  text-align: center;
  width: 100%; 
  top: 50%;
  z-index: 9999;
  color: var(--yellow);
  font-family: var(--welcome-font-family);
  font-size: var(  --font-size2);
  transition: all var(--timing);
  text-shadow: 0px 5px 1px rgba(0, 0, 0, 0.5);
  font-optical-sizing: auto;
  font-variation-settings:
  "MORF" 15,
  "SHLN" 50;
  transform-style: preserve-3d;
}


.bg-card{
  height: auto;
  width: 400px;
  aspect-ratio: 3/4;
  z-index: 55;
  transition: all var(--timing);
  transform-style: preserve-3d;
}

.astronaut{
  position: absolute;
  bottom: -100px;
  left: 0;
  height: auto;
  width: var(--astro-width);
  scale: 1.125;
  transform-origin: bottom;
  z-index: 9999;
  transition: var(--timing);
  opacity: 0;
  transform-style: preserve-3d;
  aspect-ratio: 3/4;
}

.planet{
  position: absolute;
  top: 0px;
  left: 0;
  height: auto;
  width: var(--planet-width);
  z-index: 9999;
  transition: var(--timing);
  transform-style: preserve-3d;
  aspect-ratio: 3/4;
}

.sword{
  position: absolute;
  bottom: 15px;
  right: 55px;
  height: auto;
  width: 100px;
  z-index: 9999;
  transition: var(--timing);
  transform-style: preserve-3d;
  aspect-ratio: 3/4;
}







.star-1{
  position: absolute;
  top: 10px;
  left: 50px;
  height: auto;
  width: var(--star-width);
  z-index: 85;
  transform: rotate(35deg);
  transition: var(--timing-2);
  transform-style: preserve-3d;
  aspect-ratio: 1/1;
}

.star-2{
  position: absolute;
  top: 20px;
  left: 200px;
  height: auto;
  width: var(--star-width);
  z-index: 85;
  transform: rotate(-25deg);
  transition: var(--timing-2);
  transform-style: preserve-3d;
  aspect-ratio: 1/1;
}

.star-3{
  position: absolute;
  top: 150px;
  left: 220px;
  height: auto;
  width: var(--star-width);
  z-index: 85;
  transform: rotate(45deg);
  transition: var(--timing-2);
  transform-style: preserve-3d;
  aspect-ratio: 1/1;
}





.sparkle-1{
  position: absolute;
  bottom: 0px;
  height: auto;
  width: var(--star-width);
  z-index: 85;
  transform: rotate(35deg);
  transition: var(--timing-2);
  opacity: 0;
  transform-style: preserve-3d;
  aspect-ratio: 1/1;
}

.sparkle-2{
  position: absolute;
  bottom: 0px;
  left: 200px;
  height: auto;
  width: var(--star-width);
  z-index: 85;
  transform: rotate(-25deg);
  transition: var(--timing-2);
  opacity: 0;
  transform-style: preserve-3d;
  aspect-ratio: 1/1;
}

.sparkle-3{
  position: absolute;
  bottom: 100px;
  left: 200px;
  height: auto;
  width: var(--star-width);
  z-index: 85;
  transform: rotate(45deg);
  transition: var(--timing-2);
  opacity: 0;
  transform-style: preserve-3d;
  aspect-ratio: 1/1;
}







/* For mobile screens */
@media (max-width: 768px) {

/* Reset WebKit-based browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  display: none; /* Ensure the scrollbar is visible */
}

/* Reset Firefox */
html, body, * {
  scrollbar-width: 0px; /* Restore the default width */
  -ms-overflow-style:0px; /* Restore default style for IE */
}


  .welcometext {
    font-size: var(--welcome-font-size-mobile);
  }
  .cursor {
    font-size: var(--welcome-font-size-mobile);
  }
  
.title {
  font-size: var(    --font-size2-mobile2);
}

.abouttext-content{
  width: 75vw;

}

.abouttext{
  border: var(--about-border);
}

.sparkle {
  position: absolute;
  top: -40px;
  right: -20px;
  height: 80px;
  width: 60px;
  background: var(--yellow);
  mask: radial-gradient(#0000 71%, #000 72%) 10000% 10000%/99.5% 99.5%;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.9);
  animation: sparkleEffect 5s infinite ease-in-out;
  z-index: 99;
}

.sparkle::after {
  --b: 3px; 
  top: 0; 
  left: 0; 
  height: 80px;
  width: 60px;
  mask: radial-gradient(#0000 71%, #000 72% calc(72% + var(--b)), #0000 calc(73% + var(--b))) 10000% 10000%/99.5% 99.5%;
  z-index: 100;
}





.bg-card{
  height: auto;
  width: 200px;
}

.astronaut{
  bottom: -50px;
  left: 0;
  height: auto;
  width: var(--astro-width-mobile); 
}

.planet{
  top: 0px;
  left: 0;
  height: auto;
  width: var(--planet-width-mobile);
}

.sword{
  bottom: 10px;
  right: 30px;
  height: auto;
  width: 50px;
}







.star-1{
  top: 10px;
  left: 20px;
  height: auto;
  width: var(--star-width-mobile);
}

.star-2{
  top: 20px;
  left: 90px;
  height: auto;
  width: var(--star-width-mobile);
}

.star-3{
  position: absolute;
  top: 80px;
  left: 100px;
  height: auto;
  width: var(--star-width-mobile);
}





.sparkle-1{
  bottom: 0px;
  height: auto;
  width: var(--star-width-mobile);
  opacity: 0;
}

.sparkle-2{
  bottom: 0px;
  left: 90px;
  height: auto;
  width: var(--star-width-mobile);
  opacity: 0;
}

.sparkle-3{
  bottom: 10px;
  left: 10px;
  height: auto;
  width: var(--star-width-mobile);
  opacity: 1;
}



.card .title {
  opacity: 1;
  transform: translateY(-5rem) scale(1.525);
  rotate: x calc(var(--rotation) * -1);
  filter: drop-shadow(0px 20px 35px rgba(0, 0, 0, 0.5));
}

.card .astronaut {
  opacity: 1;
  transform: translateY(-3rem) rotate(10deg);
  rotate: x calc(var(--rotation) * -.5);
}

.card .sword {
  transform: translateY(0rem) translateX(0rem) rotate(10deg);
}

.card .planet {
  opacity: 1;
  transform: translateY(-3rem) translateX(-.7rem) rotate(-15deg);
  rotate: x calc(var(--rotation) * -1);
}

.card .star-1 {
  opacity: 1;
  transform: translateY(-3rem) translateX(1rem) rotate(-25deg);
  rotate: x calc(var(--rotation) * -.5);
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.2));
}

.card .star-2 {
  opacity: 1;
  transform: translateY(-3rem) translateX(-1rem) rotate(50deg);
  rotate: x calc(var(--rotation) * -.5);
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.2));
}

.card .star-3 {
  opacity: 1;
  transform: translateY(-4.5rem) translateX(-.5rem) rotate(-50deg);
  rotate: x calc(var(--rotation) * -.5);
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.2));
}

.card.sparkle-1 {
  opacity: 1;
  transform: translateY(-6.5rem) translateX(0rem) rotate(-50deg);
  rotate: x calc(var(--rotation) * -.5);
}

.card .sparkle-2 {
  opacity: 1;
  transform: translateY(-5rem) translateX(1rem) rotate(50deg);
  rotate: x calc(var(--rotation) * -.5);
}

.card .sparkle-3 {
  opacity: 1;
  transform: translateY(-5rem) translateX(-1rem) rotate(-25deg);
  rotate: x calc(var(--rotation) * -.5);
}



}





/* For tablet screens (768px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {

/* Reset WebKit-based browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  display: none; /* Ensure the scrollbar is visible */
}

/* Reset Firefox */
html, body, * {
  scrollbar-width: 0px; /* Restore the default width */
  -ms-overflow-style:0px; /* Restore default style for IE */
}


  .welcometext {
    font-size: var(--welcome-font-size-tablet);
  }
  .cursor {
    font-size: var(--welcome-font-size-tablet);
  }

  .title {
    font-size: var(    --font-size2-tablet2);
  }

  .abouttext-content{
    width: 65vw;
  }
  .abouttext{
    border: var(--about-border);
  }
  
  .bg-card{
    height: auto;
    width: 300px;
  }
  
  .astronaut{
    bottom: -100px;
    left: 0;
    height: auto;
    width: var(--astro-width-tablet);
  }
  
  .planet{
    top: 0px;
    left: 0;
    height: auto;
    width: var(--planet-width-tablet);
  }
  
  .sword{
    bottom: 10px;
    right: 40px;
    height: auto;
    width: 80px;
  }
  
  .star-1{
    top: 10px;
    left: 30px;
    height: auto;
    width: var(--star-width-tablet);
  }
  
  .star-2{
    top: 15px;
    left: 150px;
    height: auto;
    width: var(--star-width-tablet);
  }
  
  .star-3{
    top: 120px;
    left: 160px;
    height: auto;
    width: var(--star-width-tablet);
  }
  
  .sparkle-1{
    bottom: 50px;
    height: auto;
    width: var(--star-width-tablet);
  }
  
  .sparkle-2{
    bottom: 10px;
    left: 150px;
    height: auto;
    width: var(--star-width-tablet);
  }
  
  .sparkle-3{
    bottom: 40px;
    left: 200px;
    height: auto;
    width: var(--star-width-tablet);
  }
  
  .card .title {
    opacity: 1;
    transform: translateY(-7rem) scale(1.525);
    rotate: x calc(var(--rotation) * -1);
    filter: drop-shadow(0px 20px 35px rgba(0, 0, 0, 0.5));
  }
  
  .card .astronaut {
    opacity: 1;
    transform: translateY(-6rem) rotate(10deg);
    rotate: x calc(var(--rotation) * -.5);
  }
  
  .card .sword {
    transform: translateY(0rem) translateX(0rem) rotate(10deg);
  }
  
  .card .planet {
    opacity: 1;
    transform: translateY(-4rem) translateX(-.7rem) rotate(-15deg);
    rotate: x calc(var(--rotation) * -1);
  }
  
  .card .star-1 {
    opacity: 1;
    transform: translateY(-4rem) translateX(3rem) rotate(-25deg);
    rotate: x calc(var(--rotation) * -.5);
    filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.2));
  }
  
  .card .star-2 {
    opacity: 1;
    transform: translateY(-3rem) translateX(-2rem) rotate(50deg);
    rotate: x calc(var(--rotation) * -.5);
    filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.2));
  }
  
  .card .star-3 {
    opacity: 1;
    transform: translateY(-6.5rem) translateX(-2rem) rotate(-50deg);
    rotate: x calc(var(--rotation) * -.5);
    filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.2));
  }
  
  .card .sparkle-1 {
    opacity: 1;
    transform: translateY(-6.5rem) translateX(-2rem) rotate(-50deg);
    rotate: x calc(var(--rotation) * -.5);
  }
  
  .card .sparkle-2 {
    opacity: 1;
    transform: translateY(-8rem) translateX(1rem) rotate(50deg);
    rotate: x calc(var(--rotation) * -.5);
  }
  
  .card .sparkle-3 {
    opacity: 1;
    transform: translateY(-9rem) translateX(-3rem) rotate(-25deg);
    rotate: x calc(var(--rotation) * -.5);
  }
  


}









@media (min-width: 1024px){


  .card:hover .title {
    opacity: 1;
    transform: translateY(-10rem) scale(1.525);
    rotate: x calc(var(--rotation) * -1);
    filter: drop-shadow(0px 20px 35px rgba(0, 0, 0, 0.2));
  }
  
  .card:hover .astronaut {
    opacity: 1;
    transform: translateY(-6rem) rotate(10deg);
    rotate: x calc(var(--rotation) * -.5);
  }
  
  .card:hover .sword {
    transform: translateY(-3rem) translateX(-.7rem) rotate(10deg);
  }
  
  .card:hover .planet {
    opacity: 1;
    transform: translateY(-5rem) translateX(-.7rem) rotate(-15deg);
    rotate: x calc(var(--rotation) * -1);
  }
  
  .card:hover .star-1 {
    opacity: 1;
    transform: translateY(-4rem) translateX(3rem) rotate(-25deg);
    rotate: x calc(var(--rotation) * -.5);
    filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.2));
  }
  
  .card:hover .star-2 {
    opacity: 1;
    transform: translateY(-3rem) translateX(-2rem) rotate(50deg);
    rotate: x calc(var(--rotation) * -.5);
    filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.2));
  }
  
  .card:hover .star-3 {
    opacity: 1;
    transform: translateY(-6.5rem) translateX(-2rem) rotate(-50deg);
    rotate: x calc(var(--rotation) * -.5);
    filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.2));
  }
  
  .card:hover .sparkle-1 {
    opacity: 1;
    transform: translateY(-6.5rem) translateX(-2rem) rotate(-50deg);
    rotate: x calc(var(--rotation) * -.5);
  }
  
  .card:hover .sparkle-2 {
    opacity: 1;
    transform: translateY(-5rem) translateX(1rem) rotate(50deg);
    rotate: x calc(var(--rotation) * -.5);
  }
  
  .card:hover .sparkle-3 {
    opacity: 1;
    transform: translateY(-9rem) translateX(-3rem) rotate(-25deg);
    rotate: x calc(var(--rotation) * -.5);
  }
  
  .card:hover .bg-card {
    transform: perspective(1200px) translateY(-5%) rotateX(25deg) translateZ(0);
    filter: drop-shadow(0px 20px 10px rgba(0, 0, 0, 0.3)) drop-shadow(0px 50px 20px rgba(0, 0, 0, 0.3));
  }
  
}











