:root {
  --exp-font-family: "Climate Crisis", sans-serif;
  --exp-font-size-pc: 50px;
  --exp-font-size-tablet: 40px;
  --exp-font-size-mobile: 40px;

  --css-component-bg: linear-gradient(
    to bottom,
    rgb(0, 0, 0),
    rgb(39, 30, 110),
    rgb(0, 0, 0),
    rgb(39, 30, 110)
  );
  --css-title-color: rgb(255, 255, 255);

  --tips-item-font-family: "Climate Crisis", sans-serif;
  --tips-item-font-size-pc: 35px;
  --tips-item-font-size-tablet: 35px;
  --tips-item-font-size-mobile: 35px;
  
  --tips-item-bg-color: rgb(0, 0, 0, 0.5);
  --tips-item-bg-color-2: rgb(0, 0, 0);
  --tips-item-font-color: rgb(255, 255, 255);
  --tips-item-border: 5px solid rebeccapurple;
  --tips-item-border-2: 2px solid rebeccapurple;

  --prof-name-font-family: "Lexend Deca", sans-serif;
  --header2-font-family: "Lexend Deca", sans-serif;

  --mountain-width-pc: calc(300px / 3);
  --mountain-round-pc: calc(300px / 25);
  --font-family: var(--prof-name-font-family);

  /*Toggle*/
  --on-text-font-family: var(--prof-name-font-family);
  --inside-switch-font-size: 30px;

  --toggie-color: rgb(116, 33, 33);
  --toggie-border: 8px solid rgb(253, 251, 91);
  --toggie-border-radius: 50px 50px 50px 50px;
  --toggie-skew: 0deg;

  --toggleball-color-actual: rgba(68, 230, 208, 0.87);
  --toggleball-color-shadow: rgba(0, 0, 0, 0.5);
  --toggleball-radious: 50%;
  --toggleball-width: 80px;
  --toggleball-height: 80px;
  --toggleball-top-margin: -40px;
  --toggleball-shadow-right: 8px;

  /*Loading dotys*/
  --font-family: var(--prof-name-font-family);
  --font-color: rgb(252, 185, 185);
  --font-size: 25px;

  --timing: 600ms;
  --jump-height: 30px;

  --dot-width: 20px;
  --dot-height: 20px;
  --dot-1-color: rgb(179, 238, 248);
  --dot-2-color: rgb(253, 255, 148);
  --dot-3-color: rgb(137, 255, 91);
  --dot-4-color: rgb(249, 134, 253);

  --codepen-btn-color: rebeccapurple;
  --codepen-btn-font-color: rgb(255, 255, 255);
  --codepen-btn-color-hover: rgb(58, 26, 90);
  --codepen-border: 2px solid white;
  --codepen-btn-disable: rgb(88, 88, 88);
  --codepen-btn-disable-hover: rgb(88, 88, 88);

  --main-menu-bg: linear-gradient(
    to bottom right,
    rgb(240, 68, 68) 10%,
    rgb(255, 123, 0) 20%,
    rgb(236, 236, 122) 30%,
    rgb(55, 139, 97) 40%,
    rgb(62, 62, 148) 50%,
    rgb(175, 78, 245) 60%,
    rgb(255, 198, 255) 80%,
    rgb(255, 85, 85) 90%
  );


  --snow-color:snow;
}

.retro-mode-enable {
  --css-component-bg: linear-gradient(to bottom, white, white);
  --css-title-color: rgb(51, 75, 209);

  --tips-item-bg-color: rgb(255, 255, 255);
  --tips-item-bg-color-2: rgb(255, 255, 255);
  --tips-item-font-color: rgb(0, 0, 0);
  --tips-item-border: 5px solid rgb(0, 0, 0);
  --tips-item-border-2: 2px solid rgb(0, 0, 0);

  --tips-item-bg-color-title: rgb(159, 217, 219);
  --tips-item-border-title: 5px solid rgb(0, 0, 0);

  --prof-name-font-family: "Lexend Deca", sans-serif;

  --header2-font-family: "Lexend Deca", sans-serif;

  /*Toggle*/
  --on-text-font-family: var(--prof-name-font-family);
  --inside-switch-font-size: 30px;

  --toggie-color: rgb(116, 33, 33);
  --toggie-border: 8px solid rgb(253, 251, 91);
  --toggie-border-radius: 10px;
  --toggie-skew: -20deg;

  --toggleball-color-actual: rgba(230, 68, 68, 0.87);
  --toggleball-color-shadow: rgb(253, 251, 91);
  --toggleball-radious: 5px;
  --toggleball-width: 60px;
  --toggleball-height: 60px;
  --toggleball-top-margin: -30px;
  --toggleball-shadow-right: 7px;

  --codepen-btn-color: rgb(0, 0, 0);
  --codepen-btn-font-color: rgb(255, 255, 255);
  --codepen-btn-color-hover: rgb(61, 61, 61);
  --codepen-border: 2px solid white;
  --codepen-btn-disable: rgb(88, 88, 88);
  --codepen-btn-disable-hover: rgb(88, 88, 88);

  --main-menu-bg: linear-gradient(
    to bottom right,
    white 10%,
    rgba(121, 168, 255, 0.5) 20%,
    rgba(57, 126, 255, 0.5)  30%,
    rgba(0, 89, 255, 0.5) 40%,
    rgba(0, 89, 255, 0.5) 50%,
    white 60%,
    white 80%,
    white 90%
  );

  --snow-color:rgb(0, 26, 255);
}









.tips-main {
  min-height: 100vh;

  /* Gradient Background */
  background: var(--main-menu-bg);

  /* Make Background Fixed */
  background-attachment: fixed;

  /* Animation */
  background-size: 600% 600%;
  animation: rainbowBorder 10s linear infinite;
}

.tips-component {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5rem;
  padding-bottom: 200px;
  padding-top: 200px;
  background: var(--css-component-bg);
  z-index: 1000;
}

.titlediv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  overflow-x: hidden;
}
.css-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.titlediv {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.css-title h1 {
  text-align: center;
  font-family: var(--exp-font-family);
  font-size: 120px;
  font-weight: 500;
  text-shadow: 5px 10px 1px rgba(0, 0, 0, 1);
  color: var(--css-title-color);
}

.tips-items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10rem;
  font-family: var(--tips-item-font-family);
  font-size: var(--tips-item-font-size-pc);
}
.tip-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  width: 420px;
  height: 320px;
  background: var(--tips-item-bg-color);
  border: var(--tips-item-border);
  overflow: hidden;
}
.tipss {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 420px;
  height: 320px;
  background-color: transparent;
  text-align: center;
}

.tips-items p {
  position: absolute;
  color: var(--tips-item-font-color);
  z-index: 55;
}

/* Button Styles */
.codepenbtn {
  position: absolute;
  bottom: -30px;
  display: flex;
  align-items: center;
  font-family: var(--tooltip-font-family);
  background-color: var(--codepen-btn-color);
  color: var(--codepen-btn-font-color);
  height: 60px;
  border-radius: 05px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  border: 2px solid transparent;
  margin-top: 15px;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease,
    border 0.3s ease;
  z-index: 999;
}

.codepenbtn:hover {
  background-color: var(--codepen-btn-color-hover);
  border: var(--codepen-border);
}

.codepenbtn:active {
  transform: scale(0.9);
}

.codepenbtn img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.codepenbtn:disabled {
  background-color: var(--codepen-btn-disable);
  color: var(--tips-item-font-color);
  cursor: not-allowed;
}

.codepenbtn:disabled:hover {
  background-color: var(--codepen-btn-disable-hover);
  color: var(--tips-item-font-color);
  cursor: not-allowed;
}

.backhome {
  display: flex;
  align-items: center;
  font-family: var(--tooltip-font-family);
  background-color: rgb(255, 255, 255);
  color: black;
  height: 60px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  border: 2px solid black;
  margin-top: 15px;
  transition:
    background-color 0.5s ease,
    transform 0.5s ease,
    border 0.5s ease;
  z-index: 999;
  box-shadow: 5px 5px 1px rgba(0, 0, 0, 1);
}

.backhome:hover {
  transform: scale(1.01);
}

.backhome:active {
  transform: scale(0.9);
}

.arrowhead {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.backhome:hover .arrowhead {
  transform: translateX(-20px);
}

.arrowline1 {
  position: absolute;
  height: 5px;
  width: 20px;
  background-color: #ffffff;
  border-radius: 100vh;
  transform: rotate(45deg);
  top: 3px;
  left: -50px;
  box-shadow: 3px -2px 1px rgba(0, 0, 0, 1);
  z-index: 998;
}

.arrowline2 {
  position: absolute;
  height: 5px;
  width: 20px;
  background-color: #ffffff;
  border-radius: 100vh;
  transform: rotate(-45deg);
  bottom: 3px;
  left: -50px;
  box-shadow: 3px 2px 1px rgba(0, 0, 0, 1);
  z-index: 998;
}

.arrowmainline {
  position: absolute;
  height: 5px;
  width: 20px;
  background-color: #ffffff;
  border-radius: 100vh;
  left: -45px;
  z-index: 999;
}

.tags {
  font-family: var(--prof-name-font-family);
  font-size: 16px;
  text-shadow: 0px 2px 8px rgb(0, 0, 0);
}

.html-tag,
.css-tag,
.js-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: var(--tips-item-font-color);
  background-color: var(--tips-item-bg-color-2);
  padding: 5px;
  border-radius: 5px;
  width: 40px;
  height: 16px;
  text-align: center;
  z-index: 999;
  cursor: pointer;
  border: var(--tips-item-border-2);
}

.html-tag {
  top: -16px;
  left: 30px;
}
.css-tag {
  top: -16px;
  left: 90px;
}
.js-tag {
  top: -16px;
  left: 150px;
}

/*Christmas Card*/
.christmasText {
  position: absolute;
  font-family: var(--font-family);
  color: var(--tips-item-font-color);
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  text-align: center;
  pointer-events: none;
  font-weight: 100;
  text-shadow:
    0 0 5px rgba(193, 202, 255, 0.8),
    0 0 10px rgba(193, 202, 255, 0.7),
    0 0 15px rgba(193, 199, 255, 0.6),
    0 0 20px rgba(193, 202, 255, 0.5),
    0 0 10px rgba(193, 199, 255, 0.4);

  animation: neonBlink 1.5s infinite alternate;
}

@keyframes neonBlink {
  0% {
    text-shadow:
      0 0 5px rgba(193, 202, 255, 0.8),
      0 0 10px rgba(193, 202, 255, 0.7),
      0 0 15px rgba(193, 199, 255, 0.6),
      0 0 20px rgba(193, 202, 255, 0.5),
      0 0 10px rgba(193, 199, 255, 0.4);
  }
  25% {
    text-shadow:
      0 0 3px rgba(193, 202, 255, 0.9),
      0 0 6px rgba(193, 202, 255, 0.8),
      0 0 9px rgba(193, 199, 255, 0.7),
      0 0 12px rgba(193, 202, 255, 0.6),
      0 0 15px rgba(193, 199, 255, 0.5);
  }
  50% {
    text-shadow: none;
  }
  75% {
    text-shadow:
      0 0 3px rgba(193, 202, 255, 0.9),
      0 0 6px rgba(193, 202, 255, 0.8),
      0 0 9px rgba(193, 199, 255, 0.7),
      0 0 12px rgba(193, 202, 255, 0.6),
      0 0 15px rgba(193, 199, 255, 0.5);
  }
  100% {
    text-shadow:
      0 0 5px rgba(193, 202, 255, 0.8),
      0 0 10px rgba(193, 202, 255, 0.7),
      0 0 15px rgba(193, 199, 255, 0.6),
      0 0 20px rgba(193, 202, 255, 0.5),
      0 0 10px rgba(193, 199, 255, 0.4);
  }
}

.Mountains {
  position: absolute;

  height: 220px;
  width: 300px;
  overflow: hidden;

  /* Candy Cane Border */
  border: 5px solid transparent;
  border-image: linear-gradient(
      90deg,
      rgb(255, 51, 51) 20%,
      /* Red stripe ends at 20% */ white 20%,
      /* White stripe starts at 20% */ white 40%,
      /* White stripe ends at 40% */ rgb(255, 51, 51) 40%,
      /* Red stripe starts at 40% */ rgb(255, 51, 51) 60%,
      /* Red stripe ends at 60% */ white 60%,
      /* White stripe starts at 60% */ white 80%,
      /* White stripe ends at 80% */ rgb(255, 51, 51) 80%
        /* Red stripe starts again */
    )
    30;

  background: radial-gradient(
    circle at 50% 50%,
    rgb(52, 54, 133) 0%,
    rgb(28, 29, 85) 30%,
    rgb(9, 22, 46) 60%,
    rgb(3, 16, 37) 80%,
    rgb(0, 0, 0) 100%
  );

  box-shadow: 2px 5px 15px rgba(100, 0, 0, 0.6);
}

.MountainLeft1 {
  position: inherit;
  width: calc(var(--mountain-width-pc) * 1.3);
  height: calc(var(--mountain-width-pc) * 1.3);
  background: linear-gradient(
    to bottom,
    #cfdde8,
    #abbccf,
    #7d99a9,
    #52687b,
    #283648
  );
  bottom: calc(-1 * var(--mountain-width-pc) / 2);
  left: calc(-1 * var(--mountain-width-pc) / 2);

  --mask: linear-gradient(#000 0 0) 50% / calc(100% - var(--mountain-round-pc))
      calc(100% - var(--mountain-round-pc)) no-repeat,
    radial-gradient(farthest-side, #000 98%, #0000) 0 0 /
      var(--mountain-round-pc) var(--mountain-round-pc) round;
  -webkit-mask: var(--mask);
  mask: var(--mask);

  transform: rotate(-45deg);
  z-index: 100;
}

.MountainLeft2 {
  position: inherit;
  width: calc(var(--mountain-width-pc) * 1.2);
  height: calc(var(--mountain-width-pc) * 1.2);
  background: linear-gradient(
    to bottom,
    #cfdde8,
    #abbccf,
    #7d99a9,
    #52687b,
    #283648
  );
  bottom: calc(-1 * var(--mountain-width-pc) / 2);
  left: calc(-1 * var(--mountain-width-pc) / 10);

  --mask: linear-gradient(#000 0 0) 50% / calc(100% - var(--mountain-round-pc))
      calc(100% - var(--mountain-round-pc)) no-repeat,
    radial-gradient(farthest-side, #000 98%, #0000) 0 0 /
      var(--mountain-round-pc) var(--mountain-round-pc) round;
  -webkit-mask: var(--mask);
  mask: var(--mask);

  transform: rotate(-45deg);
  z-index: 200;
}

.MountainLeft3 {
  position: inherit;
  width: calc(var(--mountain-width-pc) * 1.1);
  height: calc(var(--mountain-width-pc) * 1.1);
  background: linear-gradient(
    to bottom,
    #cfdde8,
    #abbccf,
    #7d99a9,
    #52687b,
    #283648
  );
  bottom: calc(-1 * var(--mountain-width-pc) / 2);
  left: calc(1 * var(--mountain-width-pc) / 2);

  --mask: linear-gradient(#000 0 0) 50% / calc(100% - var(--mountain-round-pc))
      calc(100% - var(--mountain-round-pc)) no-repeat,
    radial-gradient(farthest-side, #000 98%, #0000) 0 0 /
      var(--mountain-round-pc) var(--mountain-round-pc) round;
  -webkit-mask: var(--mask);
  mask: var(--mask);

  transform: rotate(-45deg);
  z-index: 300;
}

.MountainMiddle {
  position: inherit;
  width: calc(var(--mountain-width-pc) * 0.9);
  height: calc(var(--mountain-width-pc) * 0.9);
  background: linear-gradient(
    to bottom,
    #cfdde8,
    #abbccf,
    #7d99a9,
    #52687b,
    #283648
  );
  bottom: calc(-1 * var(--mountain-width-pc) / 2);
  left: calc(1 * var(--mountain-width-pc) / 1);

  --mask: linear-gradient(#000 0 0) 50% / calc(100% - var(--mountain-round-pc))
      calc(100% - var(--mountain-round-pc)) no-repeat,
    radial-gradient(farthest-side, #000 98%, #0000) 0 0 /
      var(--mountain-round-pc) var(--mountain-round-pc) round;
  -webkit-mask: var(--mask);
  mask: var(--mask);

  transform: rotate(-45deg);
  z-index: 500;
}

.MountainRight1 {
  position: inherit;
  width: calc(var(--mountain-width-pc) * 1.1);
  height: calc(var(--mountain-width-pc) * 1.1);
  background: linear-gradient(
    to bottom,
    #cfdde8,
    #abbccf,
    #7d99a9,
    #52687b,
    #283648
  );
  bottom: calc(-1 * var(--mountain-width-pc) / 2);
  right: calc(1 * var(--mountain-width-pc) / 2);

  --mask: linear-gradient(#000 0 0) 50% / calc(100% - var(--mountain-round-pc))
      calc(100% - var(--mountain-round-pc)) no-repeat,
    radial-gradient(farthest-side, #000 98%, #0000) 0 0 /
      var(--mountain-round-pc) var(--mountain-round-pc) round;
  -webkit-mask: var(--mask);
  mask: var(--mask);

  transform: rotate(-45deg);
  z-index: 300;
}

.MountainRight2 {
  position: inherit;
  width: calc(var(--mountain-width-pc) * 1.2);
  height: calc(var(--mountain-width-pc) * 1.2);
  background: linear-gradient(
    to bottom,
    #cfdde8,
    #abbccf,
    #7d99a9,
    #52687b,
    #283648
  );
  bottom: calc(-1 * var(--mountain-width-pc) / 2);
  right: calc(1 * var(--mountain-width-pc) / 10);

  --mask: linear-gradient(#000 0 0) 50% / calc(100% - var(--mountain-round-pc))
      calc(100% - var(--mountain-round-pc)) no-repeat,
    radial-gradient(farthest-side, #000 98%, #0000) 0 0 /
      var(--mountain-round-pc) var(--mountain-round-pc) round;
  -webkit-mask: var(--mask);
  mask: var(--mask);

  transform: rotate(-45deg);
  z-index: 200;
}

.MountainRight3 {
  position: inherit;
  width: calc(var(--mountain-width-pc) * 1.3);
  height: calc(var(--mountain-width-pc) * 1.3);
  background: linear-gradient(
    to bottom,
    #cfdde8,
    #abbccf,
    #7d99a9,
    #52687b,
    #283648
  );
  bottom: calc(-1 * var(--mountain-width-pc) / 2);
  right: calc(-1 * var(--mountain-width-pc) / 2);

  --mask: linear-gradient(#000 0 0) 50% / calc(100% - var(--mountain-round-pc))
      calc(100% - var(--mountain-round-pc)) no-repeat,
    radial-gradient(farthest-side, #000 98%, #0000) 0 0 /
      var(--mountain-round-pc) var(--mountain-round-pc) round;
  -webkit-mask: var(--mask);
  mask: var(--mask);

  transform: rotate(-45deg);
  z-index: 100;
}

/*SnowFlakes*/
.Flake {
  position: absolute;
  z-index: 1000;
  top: -30%;
  width: 20px;
  height: 20px;
  animation:
    fall 8s linear infinite,
    sway2 4s ease-in-out infinite;
  opacity: 0.8;
  perspective: 1000px;
}

.Flake101 {
  position: absolute;
  z-index: 1000;
  top: -30%;
  width: 20px;
  height: 20px;
  animation:
    fall 4s linear infinite,
    sway2 2s ease-in-out infinite;
  opacity: 0.8;
  perspective: 1000px;
}

.Flake1 {
  left: 10%;
  animation-delay: 0s;
}

.Flake2 {
  left: 30%;
  animation-delay: 2s;
}

.Flake3 {
  left: 50%;
  animation-delay: 4s;
}

.Flake4 {
  left: 70%;
  animation-delay: 6s;
}

.Flake5 {
  left: 90%;
  animation-delay: 8s;
}

.Flake11 {
  left: 10%;
  animation-delay: 0s;
}

.Flake22 {
  left: 30%;
  animation-delay: 1s;
}

.Flake33 {
  left: 50%;
  animation-delay: 2s;
}

.Flake44 {
  left: 70%;
  animation-delay: 3s;
}

.Flake55 {
  left: 90%;
  animation-delay: 4s;
}

/* Falling animation */
@keyframes fall {
  0% {
    top: -10%;
    opacity: 1;
  }
  100% {
    top: 100%;
    opacity: 0;
  }
}

/* sway2 animation for natural falling */
@keyframes sway2 {
  0% {
    transform: translateX(0) rotateX(0deg) rotateY(0deg) rotateZ(45deg);
  }
  50% {
    transform: translateX(20px) rotateX(20deg) rotateY(-20deg) rotateZ(-45deg);
  }
  100% {
    transform: translateX(0) rotateX(0deg) rotateY(0deg) rotateZ(45deg);
  }
}

.stroke {
  height: 30px;
  width: 1px;
  background-color: var(--snow-color);
  position: absolute;
  box-shadow: 2px 2px 10px 0px var(--snow-color);
}
.stroke:before,
.stroke:after {
  content: "";
  border: 1px solid var(--snow-color);
  width: 5px;
  height: 5px;
  position: absolute;
  left: -2px;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg);
}

.stroke:after {
  bottom: 0;
  transform: rotate(-135deg);
}

.stroke-2 {
  transform: rotate(90deg);
}

.stroke-3 {
  transform: rotate(45deg);
}
.stroke-4 {
  transform: rotate(-45deg);
}

/*Christmas Card*/

/*The Clock*/

.clock-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: crosshair;
  border-radius: 50%;
}

.clock-container2 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: crosshair;
  border-radius: 50%;
}

.clock-container.glow {
  box-shadow: 0 0px 200px rgba(128, 250, 98, 0.7);
}

.clock-outline {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  position: relative;
  background-color: transparent;
  z-index: 9999;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.clock-outline2 {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  position: relative;
  background-color: rgb(0, 0, 0);
  transition: background-color 0.5s;
  z-index: 9999;
  outline: 7px dashed black;
  animation: rotateAnimation 60s linear infinite;
}

.clock-outline::before {
  content: "";
  position: absolute;
  top: -15%;
  left: -15%;
  right: -15%;
  bottom: -15%;
  border-radius: 50%;
  background: conic-gradient(
    from 180deg at 50% 50%,
    rgb(38, 38, 63),
    rgb(36, 36, 61),
    rgb(36, 36, 61),
    rgb(68, 68, 119),
    rgb(116, 116, 207),
    rgb(76, 76, 141),
    rgb(55, 55, 97),
    rgb(38, 38, 63)
  );
  z-index: 6;
  outline: 3px solid rgb(127, 151, 255);
  box-shadow: 5px 10px 5px rgba(0, 0, 0, 0.7);
}

.actual-clock {
  height: calc(100% - 50px);
  width: calc(100% - 50px);
}
.clock-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: rgb(45, 71, 55);
  border-radius: 5px;
  padding: -10px;
  z-index: 9999;
  width: 100px;
  pointer-events: none;
}

.clock-text {
  color: rgb(0, 255, 98);
  font-size: 25px;
  font-family: var(--prof-name-font-family);
}

.glow {
  color: rgb(0, 255, 98);
  text-shadow: 0 0 5px rgba(51, 255, 0, 0.8);
  box-shadow: 0 0px 100px rgba(156, 255, 131, 0.9);
  background-color: black;
}

.clock-button {
  position: absolute;
  bottom: 95%;
  left: 90%;
  transform: translateX(-50%) rotate(40deg);
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: linear-gradient(
    135deg,
    rgb(116, 116, 207) 0%,
    rgb(36, 36, 61) 100%
  );
  border-radius: 50px 50px 5px 5px;
  z-index: 5;
  border: 3px solid rgb(127, 151, 255);
  box-shadow: 0 -3px 15px rgba(0, 0, 0, 0.7);
  transition:
    transform 1s,
    background-color 1s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none; /* Disable text selection */
}

.clock-button:focus {
  outline: none;
}

.clock-button:active {
  transform: translateX(-70%) translateY(20%) rotate(55deg) scale(0.9);
  background: linear-gradient(135deg, rgb(39, 39, 66) 0%, rgb(36, 36, 61) 100%);
}

/*The Clock*/

/*Retro Toggle*/
.retro-mode {
  --on-text-font-family: var(--prof-name-font-family);
  --inside-switch-font-size: 30px;

  --toggie-color: rgb(24, 65, 104);
  --toggie-border: 8px solid rgb(119, 246, 255);
  --toggie-border-radius: 20px 20px 20px 20px;
  --toggie-skew: -10deg;

  --toggleball-color-actual: rgba(255, 241, 44, 0.87);
  --toggleball-color: rgb(228, 165, 92);
  --toggleball-radious: 5px;
  --toggleball-width: 60px;
  --toggleball-height: 60px;
  --toggleball-skew: -10deg;
  --toggleball-top-margin: -30px;
  --toggleball-shadow-right: -5px;
}

.color-switch {
  cursor: pointer;
}

/* Hide the actual checkbox */
#retro-mode-color-switch {
  display: none;
}

.retro-mode-toggle {
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 50%;
  height: 100svh;
}

.retro {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Pseudo-element for the toggle */
.retro::before {
  content: " ";
  width: 200px;
  height: 85px;
  position: absolute;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%) skewX(var(--toggie-skew));

  border-radius: var(--toggie-border-radius);
  background-color: var(--toggie-color);
  border: var(--toggie-border);

  padding-left: 40px;
  padding-top: 15px;
  transition:
    border 0.7s,
    background-color 0.5s,
    transform 0.2s;
}

/* Text styles */
.on-text,
.off-text {
  position: absolute;
  font-family: var(--on-text-font-family);
  font-size: var(--inside-switch-font-size);
  font-weight: 1000;
  opacity: 0;
  transition: opacity 0.1s;
  color: white;
  margin: 60px;
  -webkit-tap-highlight-color: transparent;
}

.ball {
  position: absolute;
  top: var(--toggleball-top-margin);
  background-color: var(--toggleball-color-shadow);
  border-radius: var(--toggleball-radious);
  width: var(--toggleball-width);
  height: var(--toggleball-height);
  transition: 0.5s
    linear(
      0,
      0.002 0.3%,
      0.009 0.6%,
      0.02 0.9%,
      0.04 1.3%,
      0.091 2%,
      0.168 2.8%,
      0.25 3.5%,
      0.34 4.2%,
      0.706 6.8%,
      0.854 7.9%,
      0.986 9%,
      1.097 10.1%,
      1.178 11.1%,
      1.211 11.6%,
      1.239 12.1%,
      1.262 12.6%,
      1.279 13.1%,
      1.292 13.6%,
      1.302 14.2%,
      1.305 14.8%,
      1.302 15.5%,
      1.292 16.2%,
      1.276 16.9%,
      1.252 17.7%,
      1.223 18.5%,
      1.054 22.5%,
      1.016 23.5%,
      0.983 24.5%,
      0.953 25.6%,
      0.931 26.7%,
      0.916 27.8%,
      0.908 29%,
      0.907 29.6%,
      0.908 30.3%,
      0.911 31%,
      0.916 31.8%,
      0.933 33.4%,
      0.983 37.3%,
      1.005 39.3%,
      1.014 40.4%,
      1.021 41.5%,
      1.025 42.6%,
      1.028 43.7%,
      1.028 45.1%,
      1.026 46.6%,
      0.999 54%,
      0.994 56.1%,
      0.992 58.3%,
      0.992 61.4%,
      1 68.7%,
      1.002 72.8%,
      0.999 87.2%,
      1
    );
  z-index: 1000;
}

.ball::after {
  position: absolute;
  content: "";
  width: calc(var(--toggleball-width) + -5px);
  height: calc(var(--toggleball-height) + -5px);
  background-color: var(--toggleball-color-actual);
  border-radius: var(--toggleball-radious);
  z-index: -2;
  right: var(--toggleball-shadow-right);

  transition: 0.5s
    linear(
      0,
      0.002 0.3%,
      0.009 0.6%,
      0.02 0.9%,
      0.04 1.3%,
      0.091 2%,
      0.168 2.8%,
      0.25 3.5%,
      0.34 4.2%,
      0.706 6.8%,
      0.854 7.9%,
      0.986 9%,
      1.097 10.1%,
      1.178 11.1%,
      1.211 11.6%,
      1.239 12.1%,
      1.262 12.6%,
      1.279 13.1%,
      1.292 13.6%,
      1.302 14.2%,
      1.305 14.8%,
      1.302 15.5%,
      1.292 16.2%,
      1.276 16.9%,
      1.252 17.7%,
      1.223 18.5%,
      1.054 22.5%,
      1.016 23.5%,
      0.983 24.5%,
      0.953 25.6%,
      0.931 26.7%,
      0.916 27.8%,
      0.908 29%,
      0.907 29.6%,
      0.908 30.3%,
      0.911 31%,
      0.916 31.8%,
      0.933 33.4%,
      0.983 37.3%,
      1.005 39.3%,
      1.014 40.4%,
      1.021 41.5%,
      1.025 42.6%,
      1.028 43.7%,
      1.028 45.1%,
      1.026 46.6%,
      0.999 54%,
      0.994 56.1%,
      0.992 58.3%,
      0.992 61.4%,
      1 68.7%,
      1.002 72.8%,
      0.999 87.2%,
      1
    );
}

/*ball-animation*/
#retro-mode-color-switch:checked + .color-switch .ball {
  transform: translateX(60px) skewX(var(--toggie-skew));
}

#retro-mode-color-switch + .color-switch .ball {
  transform: translateX(-60px);
}

#retro-mode-color-switch:checked + .color-switch .on-text {
  opacity: 1;
}

#retro-mode-color-switch:checked + .color-switch .off-text {
  opacity: 0;
}

#retro-mode-color-switch + .color-switch .off-text {
  opacity: 1;
}
#retro-mode-color-switch + .color-switch .on-text {
  opacity: 0;
}

/*Retro Toggle*/

/*Basic Loading*/
.loading {
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: progress;

  border-radius: 50%;
  border-top: 5px solid gold;
  border-bottom: 5px solid transparent;
  border-left: 5px solid gold;
  border-right: 5px solid transparent;

  animation: loading 1s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*Basic Loading*/

/*Loading dotys*/

.loading-text {
  color: var(--font-color);
  font-family: var(--font-family);
  font-size: var(--font-size);
  filter: drop-shadow(0px 20px 2.5px rgba(231, 204, 204, 0.3));
}

.loading-dotys {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.doty-1,
.doty-2,
.doty-3,
.doty-4 {
  position: relative;
  content: "";
  height: var(--dot-height);
  width: var(--dot-width);
  border-radius: 50%;
  animation: jumping-dotys 2s cubic-bezier(0.55, 1.55, 0.55, 1.55) infinite;
  filter: drop-shadow(0px 0px 10px rgba(119, 72, 115, 0.521))
    drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.3));
  transition: var(--timing);
}

.doty-1::before,
.doty-2::before,
.doty-3::before,
.doty-4::before {
  content: "🩵";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border-radius: 50%;
}

.doty-1 {
  background-color: var(--dot-1-color);
}

.doty-2 {
  background-color: var(--dot-2-color);
  animation-delay: 0.25s;
}

.doty-3 {
  background-color: var(--dot-3-color);
  animation-delay: 0.5s;
}

.doty-4 {
  background-color: var(--dot-4-color);
  animation-delay: 0.75s;
}

@keyframes jumping-dotys {
  0%,
  50%,
  75%,
  100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(calc(var(--jump-height) * -1));
  }
}

/*Loading dotys*/

/*Rainbow*/

.fun-itemys {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-family: var(--fun-item-font-family);
  font-size: 30px;
}
.itemy {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  cursor: pointer;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  margin: 15px;
  border: 5px solid transparent;
  background: linear-gradient(
    to bottom right,
    rgb(240, 68, 68) 10%,
    rgb(255, 123, 0) 20%,
    rgb(236, 236, 122) 30%,
    rgb(55, 139, 97) 40%,
    rgb(62, 62, 148) 50%,
    rgb(175, 78, 245) 60%,
    rgb(255, 198, 255) 80%,
    rgb(255, 85, 85) 90%
  );
  background-size: 600% 600%;
  animation: rainbowBorder 3s linear infinite;
  z-index: 10;
  transition: transform 150ms ease-in-out;
}

.itemy::after {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background-color: rgba(0, 7, 66, 0.5);
  backdrop-filter: blur(35px);
  z-index: 15;
}

.itemy:hover {
  border: 5px solid white;
  transform: scale(0.95);
  box-shadow: 0px 0px 10px rgba(116, 148, 255, 0.8);
}

.itemy:active {
  border: 5px solid rgb(10, 12, 99);
  transform: scale(0.95);
}

@keyframes rainbowBorder {
  0% {
    background-position: 10% 10%; /* Start the animation from the top-left corner */
  }
  14.28% {
    background-position: 20% 20%; /* Move the background slightly */
  }
  28.57% {
    background-position: 50% 50%; /* Move the background slightly */
  }
  42.85% {
    background-position: 60% 60%; /* Move the background slightly */
  }
  57.14% {
    background-position: 50% 50%; /* Move the background slightly */
  }
  71.42% {
    background-position: 40% 40%; /* Move the background slightly */
  }
  85.71% {
    background-position: 20% 20%; /* Move the background slightly */
  }
  100% {
    background-position: 10% 10%; /* Move the background to the bottom-right corner */
  }
}

.fun-itemys p {
  position: absolute;
  color: white;
  z-index: 55;
}

/*Rainbow*/

/*Phone*/

.phone {
  transform: rotate(25deg);
  cursor: cell;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 280px;
  border: 1px solid black;
  padding: 0px 0px;
  border-radius: 35px 35px 30px 30px;
  background: radial-gradient(
    circle at top right,
    /* Light source at the top-right corner */ rgba(199, 223, 255, 0.8),
    /* Bright light color */ #404892 40%,
    /* Transition to your first color */ #271e5a 80% /* Final gradient color */
  );
  box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.1); /* Shadow on the bottom-left */
}

.phone::after {
  content: "";
  position: absolute;
  top: 35px;
  width: 130px;
  height: 200px;
  border: 0.5px solid rgb(0, 0, 0);
  border-radius: 5px;
  background: url("../Assets/iphone.webp") no-repeat center center;
  background-size: cover;
  backdrop-filter: blur(10px);
}

.iphonebtn {
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 0.5px solid black;
  bottom: 10px;
  background: radial-gradient(
    circle at top right,
    /* Light source at the top-right corner */ rgba(255, 255, 255, 0.8),
    /* Bright light color */ #7c8adb 40%,
    /* Transition to your first color */ #2d2c5f 80% /* Final gradient color */
  );
  box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.8);
}

.iphonespeaker {
  position: absolute;
  height: 2px;
  width: 30px;
  border-radius: 100vh;
  border: 0.5px solid black;
  top: 18px;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.8);
  background: radial-gradient(
    circle at top right,
    /* Light source at the top-right corner */ rgba(255, 255, 255, 0.8),
    /* Bright light color */ #7c8adb 40%,
    /* Transition to your first color */ #2d2c5f 80% /* Final gradient color */
  );
}

.iphonecamera {
  position: absolute;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  border: 1px solid black;
  top: 17px;
  left: 45px;
  background: radial-gradient(
    circle at top right,
    /* Light source at the top-right corner */ rgba(255, 255, 255, 0.8),
    /* Bright light color */ #7c8adb 40%,
    /* Transition to your first color */ #2d2c5f 80% /* Final gradient color */
  );
}

/*Phone*/

/*Browser*/
/*main tab*/
.browser-tab {
  position: relative;
  width: 300px;
  height: 200px;
  background-color: white;
  border-radius: 10px;
  border: 3px solid yellow;
  overflow: hidden;
}

/*Three Buttons*/
.browserclose,
.browsertask,
.browserminimize {
  position: absolute;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

/*close btn*/
.browserclose {
  background-color: #ff7575;
  top: 0;
  right: 0;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.line1 {
  position: absolute;
  height: 3px;
  width: 20px;
  background-color: black;
  transform: rotate(45deg);
}
.line2 {
  position: absolute;
  height: 3px;
  width: 20px;
  background-color: black;
  transform: rotate(-45deg);
}

/*resizebtn*/
.browsertask {
  background-color: #fcff61;
  top: 0;
  right: 25px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.square {
  height: 10px;
  width: 10px;
  background-color: transparent;
  border: 3px solid black;
}

/*minimizebtn*/
.browserminimize {
  background-color: #50fff6;
  top: 0;
  right: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashy {
  height: 3px;
  width: 10px;
  background-color: black;
}

/*browser Content*/
.browser-inside {
  position: absolute;
  top: 25px;
  width: 300px;
  height: 200px;
  background-color: #000;
  border-block-start: 3px solid yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: blinky 1s linear infinite;
}

/*Blinking 404*/
@keyframes blinky {
  0%,
  100% {
    color: white;
  }
  50% {
    color: transparent;
  }
}
/*Browser*/

@media (max-width: 768px) {
  .css-title h1 {
    text-align: center;
    font-size: 50px;
  }

  .tips-items {
    font-size: var(--tips-item-font-size-mobile);
  }
  .tip-item {
    width: 320px;
    height: 250px;
  }
  .Mountains {
    width: 320px;
    height: 250px;
  }
  .tipss {
    width: 320px;
    height: 250px;
  }

  /*Browser*/
  /*main tab*/
  .browser-tab {
    position: relative;
    width: 250px;
    height: 175px;
    background-color: white;
    border-radius: 10px;
    border: 3px solid yellow;
    overflow: hidden;
  }

  /*browser Content*/
  .browser-inside {
    width: 250px;
    height: 180px;
  }

  .codepenbtn {
    height: 50px;
    font-size: 13px;
  }

  .codepenbtn img {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .css-title h1 {
    text-align: center;
    font-size: 70px;
  }

  .tips-items {
    font-size: var(--tips-item-font-size-tablet);
  }
}
