:root{

  --yellow:rgb(255, 245, 116);
  --red:rgb(255, 131, 131);
  --blue:rgb(161, 214, 203);
  --purple:rgb(161, 154, 211);

  --exp-font-family: "Climate Crisis", sans-serif;
  --exp-font-size-pc:50px;
  --exp-font-size-tablet:40px;
  --exp-font-size-mobile:40px;

  
  --experience-font-family:  "Lexend Deca", sans-serif; 
  --experience-font-size-pc:15px;
  --experience-font-size-tablet:15px;
  --experience-font-size-mobile:15px;
  --experience-bg-color:rgb(0, 0, 0,.5);
  --experience-font-color:white;
  --experience-border:5px solid rebeccapurple;

  --prof-name-font-family:"Lexend Deca", sans-serif;

  
  --header2-font-family:"Lexend Deca", sans-serif;

  --exp-main-bg:linear-gradient(to bottom, rgb(0, 0, 0),rgb(39, 30, 110),rgb(0, 0, 0),rgb(39, 30, 110),rgb(0, 0, 0),rgb(39, 30, 110));
 

  --logo-filter:invert(1) brightness(100%);
  --logo-filter-ops:none;

  --latest-fb-bg:rebeccapurple;

}

.retro-mode-enable{
  --yellow:rgb(255, 245, 116);
  --red:rgb(255, 131, 131);
  --blue:rgb(161, 214, 203);
  --purple:rgb(161, 154, 211);

  
  --experience-bg-color:rgba(255, 255, 255);
  --experience-font-color:rgb(0, 0, 0);
  --experience-border:3px solid rgb(0, 0, 0);

  --exp-main-bg:linear-gradient(to bottom, rgb(165, 228, 247),rgb(188, 179, 255),rgb(165, 228, 247));
  --logo-filter:none;
  --logo-filter-ops:invert(1) brightness(100%);

  --latest-fb-bg:rgb(74, 145, 226);

}



.autoplay-progress {
  position: absolute;
  right: 15.24px;
  bottom: 30.24px;
  z-index: 10;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: black;
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 5px;
  stroke: white;
  fill: none;
  stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

.autoplay-progress2 {
  position: absolute;
  right: 15.24px;
  bottom: 15.24px;
  z-index: 10;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: black;
}

.autoplay-progress2 svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 5px;
  stroke: white;
  fill: none;
  stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}



.pagecounter{
  display: none;
}







ul.control-dots li.dot.selected {
  background-color: var(--purple);
  transform: scale(1.2);
}




  
.experience-section {
  position: relative;
  text-align: center;
  background: var(--exp-main-bg);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10rem;
  padding-block-end: 10rem;
}




.expdiv{
  margin-block: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  min-width: 60vw;
  width: auto;
  overflow: hidden;
  gap: 10rem;
  border-radius: 15px;
  flex-direction: row;
  overflow: visible;
}



.exp-box{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap:wrap-reverse;
  gap: 2rem;
  min-height: 60vh;
  height: auto;
  overflow: visible;
}

.box1{
  width: auto;
  height: auto;
  border-radius: 15px;
  margin: 40px;
  position: relative;
  overflow: visible;
}

.box2{
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  justify-content: center;
  overflow: visible;
}

 .box22{
  width: auto;
  position: relative;
  font-family: var(--experience-font-family);
  font-size: var(--experience-font-size-pc);
  font-weight: 300;
  color: var(--experience-font-color);
  background-color: var(--experience-bg-color);
  padding: 20px;
  text-align: start;
  line-height: 1.6;
  word-wrap: break-word; 
  margin: 20px auto; 
  max-width: 400px;
  max-height: 500px;
  border: var(--experience-border);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.1s ease;
  cursor: pointer;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);
}

.box21{
  width: auto;
  position: relative;
  font-family: var(--experience-font-family);
  font-size: var(--experience-font-size-pc);
  font-weight: 300;
  color: var(--experience-font-color);
  background-color: none;
  padding:10px;
  text-align: start;
  line-height: 1.6;
  word-wrap: break-word; 
  margin: 0px auto; 
  max-width: 400px;
  max-height: 500px;
  border: none;
  border-radius: 0px;
  overflow: visible;
  transition: all 0.1s ease;
  cursor: pointer;
  
}






.exp-flex-column1{
  width: auto;
  height: auto;
  border: var(--experience-border);
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--experience-bg-color);
  overflow: visible;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);
}

.exp-flex-column2{
  border-radius: 0px;
  background-color: transparent;
  padding: 0px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0rem;
  height: auto;

}


.exptext {
  font-family: var(--exp-font-family);
  font-size: var(--exp-font-size-pc);
  font-weight: 100;
  text-shadow: 1px 5px 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  margin: 0px;
  color: var(--experience-font-color);
  max-width: 400px;
  text-align: center;
}



.poweredby2 {
  position: absolute;
  top: -20px;
  right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  user-select: none;
  z-index: 100;
}

.unsplashlogo {
  padding: 1px;
  width: 20px;
  height: 20px;
  filter: var(--logo-filter);
  cursor: pointer;
  border-radius: 50%;
}


.poweredby2 p {
  position: relative;
  font-family: var(--experience-font-family);
  font-size:10px;
  font-weight: 300;
  color: var(--experience-font-color);
  text-align: start;
  line-height: 1.6;
  word-wrap: break-word; 
  cursor: pointer;
}

.poweredby1 {
  position: absolute;
  top: -20px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
  user-select: none;
  z-index: 100;
}

.metalogo {
  padding: 1px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  margin-left: 5px;
  filter: var(--logo-filter-ops);
}



.poweredby1 p {
  position: relative;
  font-family: var(--experience-font-family);
  font-size:10px;
  font-weight: 300;
  color: var(--experience-font-color);
  text-align: start;
  line-height: 1.6;
  word-wrap: break-word; 
}

.latest{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: var(--experience-font-family);
  font-size:20px;
  font-weight: 300;
  color: var(--experience-font-color);
  text-align: center;
  line-height: 1.6;
  word-wrap: break-word; 
  cursor: pointer;
  padding: 5px;
  background-color: var(--latest-fb-bg);
  border-radius: 5px;
  margin: 0 auto;
  user-select: none;
  margin-bottom: 40px;
}












.artheader{
  font-family: var(--header2-font-family);
  font-size: var(--exp-font-size-pc);
  font-weight: 100;
  margin: 0;
}

.art{
  border-radius: 0px;
  background-color: transparent;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5rem;
  padding-block-end: 100px;
  margin: 0%;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arttext {
  width: auto;
  position: relative;
  font-family: var(--experience-font-family);
  font-size: var(--experience-font-size-pc);
  font-weight: 300;
  color: var(--experience-font-color);
  background-color: var(--experience-bg-color);
  padding: 20px;
  text-align: start;
  line-height: 1.6;
  word-wrap: break-word; 
  margin: 20px auto; 
  max-width: 400px;
  max-height: 500px;
  border: var(--experience-border);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.1s ease;
  cursor: pointer;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);
}


.arttext-content {
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}











.automationheader{
  font-family: var(
  --header2-font-family);
  font-size: var(--exp-font-size-pc);
  font-weight: 100;
  margin: 0;
}

.automation{
  border-radius: 0px;
  background-color: transparent;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5rem;
  padding-block-end: 100px;
  margin: 0%;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.automationtext {
  width: auto;
  position: relative;
  font-family: var(--experience-font-family);
  font-size: var(--experience-font-size-pc);
  font-weight: 300;
  color: var(--experience-font-color);
  background-color: var(--experience-bg-color);
  padding: 20px;
  text-align: start;
  line-height: 1.6;
  word-wrap: break-word; 
  margin: 20px auto; 
  max-width: 400px;
  max-height: 500px;
  border: var(--experience-border);
  border-radius: 20px;
  overflow: visible;
  transition: all 0.1s ease;
  cursor: pointer;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);
}


.automationtext-content {
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}

.pythonimg{
  position: absolute;
  top: -30px;
  right:-20px;
}

.pythonimg img{
  width: 60px;
  height: 60px;
  background-color: rebeccapurple;
  border-radius: 50%;
  border: 5px solid yellow;
  animation: sway 5s infinite linear(0, 0.011 0.8%, 0.041 1.6%, 0.158 3.3%, 0.814 9.5%, 1.04 12.1%, 1.123 13.4%, 1.185 14.7%, 1.226 16%, 1.248 17.3%, 1.252 18.9%, 1.231 20.7%, 1.19 22.6%, 1.058 27.4%, 1 29.9%, 0.958 32.6%, 0.938 35.4%, 0.941 39%, 0.999 48.1%, 1.016 53.5%, 0.996 71.3%, 1);
}


@keyframes sway {
  0% {
    transform: scale(1) rotate(0deg);
  }
  20% {
    transform: scale(1.1) rotate(10deg);
  }
  40% {
    transform: scale(1.15) rotate(-5deg);
  }
  60% {
    transform: scale(1.1) rotate(5deg);
  }
  80% {
    transform: scale(1.12) rotate(-5deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}



.everydayheader{
  font-family: var(
  --header2-font-family);
  font-size: var(--exp-font-size-pc);
  font-weight: 100;
  margin: 0;
}

.everyday{
  border-radius: 0px;
  background-color: transparent;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5rem;
  padding-block-end: 100px;
  margin: 0%;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.everydaytext {
  width: auto;
  position: relative;
  font-family: var(--experience-font-family);
  font-size: var(--experience-font-size-pc);
  font-weight: 300;
  color: var(--experience-font-color);
  background-color: var(--experience-bg-color);
  padding: 20px;
  text-align: start;
  line-height: 1.6;
  word-wrap: break-word; 
  margin: 20px auto; 
  max-width: 400px;
  max-height: 500px;
  border: var(--experience-border);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.1s ease;
  cursor: pointer;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);
}


.everydaytext-content {
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}







.read-more1 {
  background: transparent;
  border: 1px solid black;
  color: white;
  font-family: var(--prof-name-font-family);
  background-color: rebeccapurple;
  font-size: 16px;
  cursor: pointer;
  padding: 8px;
  border-radius: 5px;
  margin-top: 15px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
}

.read-more1:hover {
  background-color: rgb(62, 26, 99);
  transition: 150ms;
}

.read-more1:active {
  transform: scale(0.9);
}


.read-more2 {
  background: transparent;
  border: 1px solid black;
  color: white;
  font-family: var(--prof-name-font-family);
  background-color: rebeccapurple;
  font-size: 16px;
  cursor: pointer;
  padding: 8px;
  border-radius: 5px;
  margin-top: 15px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
}

.read-more2:hover {
  background-color: rgb(62, 26, 99);
  transition: 150ms;
}

.read-more2:active {
  transform: scale(0.9);
}


.read-more3 {
  background: transparent;
  border: 1px solid black;
  color: white;
  font-family: var(--prof-name-font-family);
  background-color: rebeccapurple;
  font-size: 16px;
  cursor: pointer;
  padding: 8px;
  border-radius: 5px;
  margin-top: 15px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
}

.read-more3:hover {
  background-color: rgb(62, 26, 99);
  transition: 150ms;
}

.read-more3:active {
  transform: scale(0.9);
}





















.artphone {
  margin:  auto;
  cursor:cell;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 540px;
  border: 3px solid black;
  padding: 0px 0px;
  border-radius: 55px 55px 50px 50px;
  background: radial-gradient(
    circle at top right, /* Light source at the top-right corner */
    rgba(199, 223, 255, 0.8), /* Bright light color */
    #404892 40%, /* Transition to your first color */
    #271e5a 80% /* Final gradient color */
  );
}


.artphone::after {
  content: '';
  position: absolute;
  top: 60px;
  width: 265px;
  height: 410px;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 5px;
  background: url('../Assets/iphone.webp') no-repeat center center;
  background-size: cover;
  backdrop-filter: blur(10px);
}

.justposted {
  position: absolute;
  width: 150px;
  height: 50px;
  border: 2px solid rgb(255, 255, 255);
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  top: 70px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.justposted p {
  font-family: var(--prof-name-font-family);
  font-size: 13px;
  font-weight: 300;
  color: white;
}

.artpost {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;   
  width: 250px;
  height: 250px;
  border: 3px solid rgb(255, 255, 255);
  border-radius: 25px;
  background: linear-gradient(to bottom, #f1f1f1, #b6b6b6);
  backdrop-filter: blur(10px);
  overflow: hidden; 
  z-index: 999;
  opacity: 0;
  transform: scale(0.98);
  animation: fade-in 0.5s ease-in-out forwards;
}





.profpic {
  position: absolute;
  width: 40px;
  height: 40px;
  border: 2px solid black;
  border-radius: 50%;
  top: 7px;
  left: 20px;
  background: linear-gradient(to bottom, #94fcfc, #94fc99);
  box-shadow: 0px 2px 10px rgba(151, 151, 151, 0.8);
}

.profname{
  position: absolute;
  top: 0px;
  right: 50px;
  font-family: var(--prof-name-font-family);
  font-size: 20px;
  z-index: 999;
  font-weight: 400;
  text-shadow: 0px 2px 10px rgba(78, 53, 0, 0.8);
}

.postbarrier{
  width: 250px;
  height: 200px;
  border-block-start: 3px solid black;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background: transparent;
  margin-top: 70px;
  overflow: hidden; 
}


.pic1,
.pic2,
.pic3,
.pic4,
.pic5,
.pic6,
.pic7,
.pic8,
.pic9 {
  max-width: 100%; 
  object-fit:fill; 
  background-color: #000000;
}









/**/


.automationpc {
  margin:  auto;
  cursor:cell;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 450px;
  height: 300px;
  border: 3px solid black;
  padding: 0px 0px;
  border-radius: 40px;
  background: radial-gradient(
    circle at top right, /* Light source at the top-right corner */
    rgba(199, 223, 255, 0.8), /* Bright light color */
    #404892 40%, /* Transition to your first color */
    #271e5a 80% /* Final gradient color */
  );
}


.automationpc::after {
  content: '';
  position: absolute;
  top: 10px;
  width: 420px;
  height: 275px;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 30px;
  background: url('../Assets/windows.webp') no-repeat center center;
  background-size: cover;
  backdrop-filter: blur(10px);
}

.justposted2 {
  position: absolute;
  width: 100px;
  height: 30px;
  border: 2px solid rgb(255, 255, 255);
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  top: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.justposted2 p {
  font-family: var(--prof-name-font-family);
  font-size: 13px;
  font-weight: 300;
  color: white;
}



.automationpost {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;   
  width: 250px;
  height: 180px;
  border: 3px solid rgb(255, 255, 255);
  border-radius: 25px;
  background: linear-gradient(to bottom, #f1f1f1, #b6b6b6);
  backdrop-filter: blur(10px);
  overflow: hidden; 
  z-index: 999;
  opacity: 0;
  transform: scale(0.98);
  animation: fade-in 0.5s ease-in-out forwards;
}



.profpic34{
  position: absolute;
  width: 25px;
  height: 25px;
  border: 2px solid black;
  border-radius: 50%;
  top: 5px;
  left: 20px;
  background: linear-gradient(to bottom, #ffd0c5, #561e61);
  box-shadow: 0px 2px 10px rgba(151, 151, 151, 0.8);
}

.profname2{
  position: absolute;
  top: -10px;
  right: 10px;
  font-family: var(--prof-name-font-family);
  font-size: 20px;
  z-index: 999;
  font-weight: 400;
  text-shadow: 0px 2px 10px rgba(78, 53, 0, 0.8);
}

.postbarrier2{
  width: 250px;
  height: 180px;
  border-block-start: 3px solid black;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background: transparent;
  margin-top: 80px;
  overflow: hidden; 
}

.bot1,
.bot2,
.bot3,
.bot4,
.bot5 {
  width: 100%; /* Ensure the image takes up the full container width */
  height: auto; /* Maintain the aspect ratio */
  max-width: 500px; /* Limit the maximum width to avoid oversized images */
  object-fit: cover; /* Preserve the image aspect ratio while filling the container */
  background-color: #000000;
}





/**/

.everydayphone {
  margin:  auto;
  cursor:cell;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 540px;
  border: 3px solid black;
  padding: 0px 0px;
  border-radius: 55px 55px 50px 50px;
  background: radial-gradient(
    circle at top right, /* Light source at the top-right corner */
    rgba(199, 223, 255, 0.8), /* Bright light color */
    #404892 40%, /* Transition to your first color */
    #271e5a 80% /* Final gradient color */
  );
}


.everydayphone::after {
  content: '';
  position: absolute;
  top: 60px;
  width: 265px;
  height: 410px;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 5px;
  background: url('../Assets/whatsapp.webp') no-repeat center center;
  background-size: cover;
  backdrop-filter: blur(10px);
}




.phonebtn1{
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 3px solid black;
  bottom: 10px;
  background: radial-gradient(
    circle at top right, /* Light source at the top-right corner */
    rgba(255, 255, 255, 0.8), /* Bright light color */
    #7c8adb 40%, /* Transition to your first color */
    #2d2c5f 80% /* Final gradient color */
  );
}


.phonespeaker1{
  position: absolute;
  height: 5px;
  width: 60px;
  border-radius: 100vh;
  border: 2px solid black;
  top: 25px;
  background: radial-gradient(
    circle at top right, /* Light source at the top-right corner */
    rgba(255, 255, 255, 0.8), /* Bright light color */
    #7c8adb 40%, /* Transition to your first color */
    #2d2c5f 80% /* Final gradient color */
  );
}

.phonecamera1{
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius:50%;
  border: 3px solid black;
  top: 24px;
  left: 90px;
  background: radial-gradient(
    circle at top right, /* Light source at the top-right corner */
    rgba(255, 255, 255, 0.8), /* Bright light color */
    #7c8adb 40%, /* Transition to your first color */
    #2d2c5f 80% /* Final gradient color */
  );
}







.profpic3 {
  position: absolute;
  width: 40px;
  height: 40px;
  border: 2px solid black;
  border-radius: 50%;
  top: 7px;
  left: 20px;
  background: linear-gradient(to bottom, #94fcfc, #94fc99);
}

.profname3{
  position: absolute;
  top: 0px;
  right: 50px;
  font-family: var(--prof-name-font-family);
  font-size: 20px;
  z-index: 999;
  font-weight: 400;
  text-shadow: 0px 2px 10px rgba(78, 53, 0, 0.8);
}

.postbarrier3{
  width: 250px;
  height: 200px;
  border-block-start: 3px solid black;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background: transparent;
  margin-top: 70px;
  overflow: hidden; 
}


.pic1,
.pic2,
.pic3,
.pic4,
.pic5,
.pic6,
.pic7,
.pic8,
.pic9 {
  max-width: 100%; 
  object-fit:fill; 
  background-color: #000000;
}


/* General Fade-in Animation */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.98);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.fade-in {
  animation: fade-in 0.5s ease-in-out forwards;
}

/* Just Posted 3 */
.justposted3 {
  position: absolute;
  width: 150px;
  height: 50px;
  border: 2px solid rgb(255, 255, 255);
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  top: 70px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0; /* Start hidden for fade-in effect */
  transform: scale(0.98); /* Slightly scaled down at first */
}

.justposted3 p {
  font-family: var(--prof-name-font-family);
  font-size: 13px;
  font-weight: 300;
  color: white;
}

/* Everyday Post */
.everydaypost {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  border: 3px solid rgb(255, 255, 255);
  border-radius: 25px;
  background: linear-gradient(to bottom, #f1f1f1, #b6b6b6);
  backdrop-filter: blur(10px);
  overflow: hidden;
  z-index: 999;
  opacity: 0; /* Start hidden for fade-in effect */
  transform: scale(0.98); /* Slightly scaled down at first */
}

.everydaypost.fade-in {
  animation: fade-in 0.5s ease-in-out forwards;
}

/* Receiver Message */
.recievermsg {
  position: absolute;
  border: 2px solid rgb(0, 0, 0);
  z-index: 999;
  background-color: rgb(161, 165, 226);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: start;
  opacity: 0; /* Start hidden for fade-in effect */
  transform: scale(0.98); /* Slightly scaled down at first */
}

.recievermsg.fade-in {
  animation: fade-in 0.5s ease-in-out forwards;
}

/* Sender Message */
.sendermsg {
  position: absolute;
  width: 150px;
  height: 50px;
  border: 2px solid rgb(0, 0, 0);
  z-index: 999;
  background-color: rgb(98, 184, 136);
  backdrop-filter: blur(10px);
  bottom: 150px;
  right: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: end;
  opacity: 0; /* Start hidden for fade-in effect */
  transform: scale(0.98); /* Slightly scaled down at first */
}

.sendermsg.fade-in {
  animation: fade-in 0.5s ease-in-out forwards;
}

.sendermsg p {
  font-family: var(--prof-name-font-family);
  font-size: 20px;
  font-weight: 300;
  color: rgb(0, 0, 0);
  margin-right: 10px;
}

.smsg1{
  width: 50px;
  height: 30px;
}

.smsg2{
  width: 150px;
  height: 30px;
}
.smsg3{
  width: 150px;
  height: 30px;
}


.recievermsg {
  position: absolute;
  border: 2px solid rgb(0, 0, 0);
  z-index: 999;
  background-color: rgb(161, 165, 226);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: start;

}

.recievermsg p {
  font-family: var(--prof-name-font-family);
  font-size: 20px;
  font-weight: 300;
  color: rgb(0, 0, 0);
  margin-left: 10px;
}

.rmsg1{
  width: 250px;
  height: 110px;
  top: 230px;
  left: 10px;
}

.rmsg2 {
  width: 220px;
  height: 220px;
  display: flex;
  flex-direction: column; 
  align-items: center;
  padding: 8px; 
  background-color: rgb(161, 165, 226); 
  border-radius: 8px; 
  overflow: hidden; 
  text-align: start; 
  top: 100px;
  left: 20px;
}

.rmsg2 p{
  margin-bottom: 10px;
}

.rmsg2 img {
  max-width: 100%; 
  max-height: calc(100% - 20px);
  border: 2px solid black;
  object-fit: contain; 
  border-radius: 6px; 
  top: 10px;
  background-color: none;
}

.rmsg3{
  width: 220px;
  height: 60px;
  top: 250px;
  left: 20px;
}

/* Animations */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.98);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}




















@media (max-width: 768px) {


/* Reset WebKit-based browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  display: none; /* Ensure the scrollbar is visible */
}

/* Reset Firefox */
html, body, * {
  scrollbar-width: 0px; /* Restore the default width */
  -ms-overflow-style:0px; /* Restore default style for IE */
}

.exp-box{
  flex-wrap:wrap;
}



.read-more3:hover {
  background-color: rgb(62, 26, 99);
}
.exp-flex-column1{
  width: 100vw;
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.box22{
  width: 75vw;
}


.expdiv{
  border: none;
  min-width: 100vw;
  gap: 10rem;
  border-radius: 0px;
  box-shadow: none;
}


.exptext {
  font-size: var(--exp-font-size-mobile);
}


.art{
  gap: 5rem;
}

.automation{
  gap: 5rem;
}

.everyday{
  gap: 5rem;
}

.artheader{
  font-size: var(--exp-font-size-tablet);
}


.arttext {
  font-size: var(--experience-font-size-mobile);
  width: 75vw;
  border: var(--about-border);
}

.pythonimg img{
  width: 35px;
  height: 35px;
}



.artphone {
  width: 250px;
  height: 500px;
  border: 3px solid black;
}


.artphone::after {
  top: 50px;
  width: 230px;
  height: 380px;
  border: 2px solid rgb(0, 0, 0);
}


.phonespeaker1{
  width: 50px;
  border: 2px solid black;
  top: 20px;
}

.phonecamera1{
  width: 6px;
  height: 6px;
  top: 20px;
  left: 80px;
}

.phonebtn1{
  bottom: 10px;
}

.justposted{
  width: 120px;
  height: 40px;
  top: 70px;
  border-radius: 5px;
}

.justposted p{
  font-size: 12px;
}





.artpost {
  width: 200px;
  height: 200px;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 15px;
}

.profpic {
  width: 25px;
  height: 25px;
  border: 1.5px solid black;
  top: 3px;
  left: 20px;
}

.profname{
  top: -5px;
  right: 50px;
  font-size: 15px;
  font-weight: 300;
}

.postbarrier{
  border-block-start: 2px solid black;
}








/**/


.automationheader{
  font-size: var(--exp-font-size-tablet);
}


.automationtext {
  font-size: var(--experience-font-size-mobile);
  width: 75vw;
  border: var(--about-border);
}





.automationpc {
  width: 320px;
  height: 250px;
  border: 2px solid black;
  border-radius: 30px;
}


.automationpc::after {
  top: 8px;
  width: 300px;
  height: 230px;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 25px;
}

.justposted2{
  width: 120px;
  height: 40px;
  top: 20px;
  border-radius: 5px;
}

.justposted2 p{
  font-size: 12px;
}



.automationpost {
  margin-top: 50px;
  width: 150px;
  height: 110px;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 15px;
}


.profpic34{
  width: 15px;
  height: 15px;
  border: 1.5px solid black;
  top: 5px;
  left: 10px;
}

.profname2{
  top: -5px;
  right: 20px;
  font-size: 15px;
  font-weight: 300;
}

.postbarrier2{
  border-block-start: 2px solid black;
  width: 150px;
  height: 130px;
}





/**/


.everydayheader{
  font-size: var(--exp-font-size-tablet);
}


.everydaytext {
  font-size: var(--experience-font-size-mobile);
  width: 75vw;
  border: var(--about-border);
}



.everydayphone {
  width: 250px;
  height: 500px;
  border: 3px solid black;
}


.everydayphone::after {
  top: 50px;
  width: 230px;
  height: 380px;
  border: 2px solid rgb(0, 0, 0);
}





/* Message container styles */
.sendermsg {
  bottom: 100px;
  right: 20px;
}

.sendermsg p {
  font-size: 18px;
}

.smsg1{
  width: 50px;
  height: 30px;
}

.smsg2{
  width: 120px;
  height: 30px;
}
.smsg3{
  width: 120px;
  height: 30px;
}


.recievermsg p {
  font-size: 15px;
  margin-left: 10px;
}

.rmsg1{
  width: 200px;
  height: 110px;
  top: 200px;
  left: 20px;
}

.rmsg2 {
  width: 180px;
  height: 200px;
  top: 100px;
  left: 20px;
}

.rmsg3{
  width: 180px;
  height: 60px;
  top: 250px;
  left: 20px;
}




}








/* For tablet screens (768px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {

/* Reset WebKit-based browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  display: none; /* Ensure the scrollbar is visible */
}

/* Reset Firefox */
html, body, * {
  scrollbar-width: 0px; /* Restore the default width */
  -ms-overflow-style:0px; /* Restore default style for IE */
}
.exp-box{
  flex-wrap:wrap;
}
.exp-flex-column1{
  width: 100vw;
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.exptext {
  font-size: var(--exp-font-size-tablet);
}

.read-more3:hover {
  background-color: rgb(62, 26, 99);
}
.artheader{
  font-size: var(--exp-font-size-pc);
}


.arttext {
  font-size: var(--experience-font-size-tablet);
  width: 65vw;
  border: var(--about-border);
}

.pythonimg img{
  width: 50px;
  height: 50px;
}



.artphone {
  width: 250px;
  height: 500px;
  border: 3px solid black;
}


.artphone::after {
  top: 50px;
  width: 230px;
  height: 380px;
  border: 2px solid rgb(0, 0, 0);
}


.phonespeaker1{
  width: 50px;
  border: 2px solid black;
  top: 20px;
}

.phonecamera1{
  width: 6px;
  height: 6px;
  top: 20px;
  left: 80px;
}

.phonebtn1{
  bottom: 10px;
}

.justposted{
  width: 120px;
  height: 40px;
  top: 70px;
  border-radius: 5px;
}

.justposted p{
  font-size: 12px;
}



.artpost {
  width: 200px;
  height: 200px;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 15px;
}

.profpic {
  width: 25px;
  height: 25px;
  border: 1.5px solid black;
  top: 3px;
  left: 20px;
}

.profname{
  top: -5px;
  right: 50px;
  font-size: 15px;
  font-weight: 300;
}

.postbarrier{
  border-block-start: 2px solid black;
}








/**/
.automationheader{
  font-size: var(--exp-font-size-tablet);
}


.automationtext {
  font-size: var(--experience-font-size-tablet);
  width: 65vw;
  border: var(--about-border);
}





.automationpc {
  width: 500px;
  height: 300px;
  border: 3px solid black;
  border-radius: 30px;
}


.automationpc::after {
  top: 15px;
  width: 480px;
  height: 270px;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 25px;
}

.justposted2{
  width: 250px;
  height: 40px;
  top: 20px;
  border-radius: 5px;
}

.justposted2 p{
  font-size: 13px;
}



.automationpost {
  margin-top: 50px;
  width: 300px;
  height: 200px;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 15px;
}

.profpic3 {
  width: 25px;
  height: 25px;
  border: 1.5px solid black;
  top:2px;
  left: 30px;
}

.profname2{
  top: -5px;
  right: 120px;
  font-size: 15px;
  font-weight: 300;
}

.postbarrier2{
  border-block-start: 2px solid black;
  width: 300px;
  height: 200px;
}





/**/

.everydayheader{
  font-size: var(--exp-font-size-pc);
}


.everydaytext {
  font-size: var(--experience-font-size-tablet);
  width: 65vw;
  border: var(--about-border);
}


.everydayphone {
  width: 250px;
  height: 500px;
  border: 3px solid black;
}


.everydayphone::after {
  top: 50px;
  width: 230px;
  height: 380px;
  border: 2px solid rgb(0, 0, 0);
}

/* Message container styles */
.sendermsg {
  bottom: 100px;
  right: 20px;
}

.sendermsg p {
  font-size: 18px;
}

.smsg1{
  width: 50px;
  height: 30px;
}

.smsg2{
  width: 120px;
  height: 30px;
}
.smsg3{
  width: 120px;
  height: 30px;
}


.recievermsg p {
  font-size: 15px;
  margin-left: 10px;
}

.rmsg1{
  width: 200px;
  height: 110px;
  top: 200px;
  left: 20px;
}

.rmsg2 {
  width: 180px;
  height: 200px;
  top: 100px;
  left: 20px;
}

.rmsg3{
  width: 180px;
  height: 60px;
  top: 250px;
  left: 20px;
}




}