:root{
    --exp-font-family: "Climate Crisis", sans-serif;
    --exp-font-size-pc:50px;
    --exp-font-size-tablet:40px;
    --exp-font-size-mobile:40px;
  
    
    --blogs-item-font-family:  "Climate Crisis", sans-serif;
    --blogs-item-font-size-pc:45px;
    --blogs-item-font-size-tablet:40px;
    --blogs-item-font-size-mobile:35px;
    --blogs-item-bg-color:rgb(0, 0, 0,.5);
    --blogs-item-bg-color-2:rgb(0, 0, 0,.5);
    --blogs-item-font-color:rgb(66, 175, 175);
    --blogs-item-border:5px solid rgb(29, 48, 102);
    --blogs-item-border-2:5px solid rgb(29, 48, 102);
  
    --blogs-item-bg-color-title:rgb(159, 217, 219);
    --blogs-item-border-title:5px solid rgb(0, 0, 0);
  
    --prof-name-font-family:"Lexend Deca", sans-serif;
  
    
    --header2-font-family:"Lexend Deca", sans-serif;
}
  
  
.blogs-main {
     min-height: 100vh;
     background: linear-gradient(to bottom, rgba(157, 255, 137, 0.6),rgb(255, 255, 255),rgb(255, 255, 255)); }




.blogtitlediv{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  overflow-x: hidden;
}
.blog-title{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
.blogtitlediv {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;
  }


.blog-title h1{
    text-align: center;
    font-family: var(--prof-name-font-family);
    font-size: 100px;
    font-weight: 500;
    text-shadow: 5px 5px 1px rgba(0, 0, 0, .5);
    color: rgb(29, 48, 102);
    max-width: 80vw;
    letter-spacing: 5px;
}

.blog-item h2{
  text-align: center;
  font-family: var(--prof-name-font-family);
  font-size: 60px;
  font-weight: 500;
  text-shadow: 5px 5px 1px rgba(0, 0, 0, .5);
  color: rgb(29, 48, 102);
  max-width: 80vw;
  letter-spacing: 5px;
}
  
.blogs-items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5rem;
    padding-bottom: 200px;
    padding-top: 200px;
    background: linear-gradient(to bottom, rgb(255, 255, 255));
    z-index: 1000;
    transition: filter 0.3s ease, opacity 0.3s ease, transform 0.2s ease;
  }
  

  .blogs-items > *:hover,
  .blogs-items > *:focus {
    outline: none;
    filter: none;
    opacity: 1;
    transform: scale(1);
    transition: filter 0.3s ease, opacity 0.3s ease, transform 0.2s ease;
  }
  


  .blog-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    width: 420px;
    height: 320px;
    border: 5px solid rgb(29, 48, 102);
    overflow: hidden;
    cursor: pointer;
    background-color: white;
    transform: scale(1);
    background-size:cover;
    background-position:right;
    background-repeat: no-repeat;
    transition: border 0.3s ease, box-shadow 0.3s ease;
  }
  
  .blog-item:hover {
    border: 5px solid rgb(0, 0, 0);
    box-shadow: 0 5px 10px rgb(0, 0, 0, .5);
  }
  

.blogss{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 420px;
    height: 320px;
    background-color: transparent;
    text-align: center;
}

  
.blogs-items a{
      position: absolute;
      color: rgb(255, 255, 255);
      z-index: 55;
      text-decoration: none;
      font-size: var(--blogs-item-font-size-pc);
      font-family: var(--exp-font-family);
}



  

.tags{
  font-family: var(--prof-name-font-family);
  font-size: 16px;
  text-shadow: 0px 2px 8px rgb(0, 0, 0);
}
.blog-tag{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: rgb(29, 48, 102);
  background-color: rgb(255, 255, 255);
  padding: 5px;
  border-radius: 5px;
  width: 40px;
  height: 16px;
  text-align: center;
  z-index:999;
  cursor: pointer;
  border: 1px solid rgb(29, 48, 102);
}


.blog-tag{
  top: -16px;
  left: 10px;
}


.blog-disclaimer {
    margin: 1rem auto;
    font-size: 10px;
    color: #555;
    font-family: var(--header2-font-family);
    max-width: 70vw;
    text-align: center;
  }
  
  .blog-disclaimer a {
    color: #007bff;
    text-decoration: none;
  }
  
  .blog-disclaimer a:hover {
    text-decoration: underline;
}









  @media (max-width: 768px) {
    .blog-disclaimer {
        font-size: 12px;
        max-width: 80vw;
      } 

    .blog-title h1{
        text-align: center;
        font-size: 40px;
        max-width: 80vw;
        letter-spacing: 2px;
        text-shadow: 4px 2px 1px rgba(0, 0, 0, .3);
  }
    
  .blogs-items a{
    font-size: var(--blogs-item-font-size-mobile);
}
  .blog-item {
      width: 320px;
      height: 250px;
  }

  .blogss{
      width: 320px;
      height: 250px;
  }
  

  
}
    
      
@media (min-width: 769px) and (max-width: 1024px) {

    .blog-disclaimer {
        font-size: 12px;
        max-width: 80vw;
      }
      .blog-title h1{
        text-align: center;
        font-size: 70px;
        max-width: 80vw;
        letter-spacing: 0px;
        text-shadow: 4px 3px 1px rgba(0, 0, 0, .3);
    }
      
    .blogs-items a{
        font-size: var(--blogs-item-font-size-tablet);
  }   
}