:root{
  --projects-font-family: "Climate Crisis", sans-serif;
  --projects-font-size-pc:50px;
  --projects-font-size-tablet:40px;
  --projects-font-size-mobile:40px;

  --yellow:rgb(255, 245, 116);
  --red:rgb(255, 131, 131);
  --blue:rgb(161, 214, 203);
  --purple:rgb(161, 154, 211);

  --tooltip-bg-color: rgba(31, 22, 83, 0.7);     
  --tooltip-bg-colornav: rgb(0, 0, 0);    
  --tooltip-text-color: #ffffff;  
  --tooltip-border-color: transparent;
  --tooltip-font-family: "Lexend Deca", sans-serif; 
  --tooltip-font-size-pc: 15px;    
  --tooltip-font-size-tablet: 13px;  
  --tooltip-font-size-mobile: 12px;  
  --tooltip-padding: 5px;         
  --tooltip-border-radius: 1px;   
  --tooltip-max-width: 250px;      
  --tooltip-box-shadow: 0 0px 0px rgba(0, 0, 0, 0.3);


  --projectstext-font-family:  "Lexend Deca", sans-serif; 
  --projectstext-font-size-pc:15px;
  --projectstext-font-size-tablet:15px;
  --projectstext-font-size-mobile:15px;
  --proj-bg:  
  radial-gradient(circle at top left, rgb(78, 0, 0,.7), transparent 50%),
  radial-gradient(circle at top right, rgb(0, 5, 49,.7), transparent 50%),
  radial-gradient(circle at bottom left, rgb(0, 5, 49,.7), transparent 50%),
  radial-gradient(circle at bottom right, rgb(78, 0, 0,.7), transparent 50%),
  radial-gradient(circle, rgb(102, 51, 153,.7), transparent 0%);

  --proj-bg-2:rgb(0, 0, 0,.5);
  --projectstext-font-color:white;
  --projectstext-border:5px solid rebeccapurple;
  --projectstext-border-2:5px solid rebeccapurple;

  --projectsheader-font-size-pc:50px;
  --projectsheader-font-size-tablet:40px;
  --projectsheader-font-size-mobile:40px;
  --projectsheader-font-family: "Lexend Deca", sans-serif; 

  --project-main-bg:linear-gradient(to bottom, rgb(0, 0, 0),rgb(39, 30, 110),rgb(0, 0, 0),rgb(39, 30, 110));
  

}

.retro-mode-enable{
  --proj-bg:white;

  --proj-bg-2:rgb(0, 0, 0,.5);
  --projectstext-font-color:black;
  --projectstext-border:3px solid black;
  --projectstext-border-2:3px solid black;

  --project-main-bg:linear-gradient(to bottom, rgb(188, 179, 255),rgb(165, 228, 247));
  
}





.project-flex{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  min-height: 80vh;
  max-width: 100%;
  margin: 0 auto;
  padding-block-end: 200px;
}



.project-card {
  position: relative;
  width: 400px;
  padding: 0px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: var(--projectstext-border);
  border-radius: 20px;
  background: var(--proj-bg);
  overflow: hidden;
  transition: all 0.1s ease;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);
}





.projects-main{
  background: var(--project-main-bg);
  min-height: 100vh;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.projectsheader2{
  font-family: var(--projects-font-family);
  font-size: var(--projectsheader-font-size-pc);
  font-weight: 100;
  color: var(--projectstext-font-color);
  text-shadow: 1px 5px 10px rgba(0, 0, 0, 0.3);
}





/*second header inside*/
.projectheader{
  font-family: var(--projectsheader-font-family);
  font-size: 30px;
  font-weight: 300;
}

.projects-section {
  position: relative;
  text-align: center;
  background: transparent;
  min-height: 100vh;
}




.projectstext {
  position: relative;
  font-family: var(--projectstext-font-family);
  font-size: var(--projectstext-font-size-pc);
  font-weight: 300;
  color: var(--projectstext-font-color);
  padding: 10px;
  text-align: start;
  line-height: 1.9;
  word-wrap: break-word; 
  margin: 0px auto; 
  max-width: 300px;
  border: none;
}

.project-tags{
  font-family: var(--projectstext-font-family);
  font-size: 12px;
  font-weight: 300;
  color: black;
  line-height: 1.9;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  gap: .5rem;
  margin-bottom: 30px;
  max-width: 90%;
  cursor: pointer;
}

.project-tags > * {
  transition: filter 0.1s ease, opacity 0.1s ease;
}

.project-tags:has(.label:hover) > :not(:hover) {
  filter: blur(2px);
  opacity: 0.8;
}


.label{
  padding-inline: 10px;
  border-radius: 100vh;
  border-block-end: 1px solid black;
}

.fun{
  background-color: aquamarine;
}

.engaging{
  background-color: rgb(175, 120, 231);
}

.compatible{
  background-color: rgb(255, 146, 146);
}

.interactive{
  background-color: rgb(255, 255, 136);
}

.versatile{
  background-color: rgb(175, 120, 231);
}

.useful{
  background-color: rgb(153, 255, 133);
}

.fast{
  background-color: rgb(255, 146, 146);
}

.messages{
  background-color: rgb(255, 255, 136);
}

.reliable{
  background-color: aquamarine;
}

.tag24-7{
  background-color: rgb(250, 192, 104);
}

.minimalui{
  background-color: rgb(255, 146, 146);
}

.userfriendly{
  background-color: rgb(175, 120, 231);
}

.efficient{
  background-color: rgb(153, 255, 133);
}

.multitask{
  background-color: rgb(255, 255, 136);
}


.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  margin-block: 10px;
  padding-bottom: 5%; /* Adds a bottom margin to push the buttons away from the bottom */
}

.button-container > * {
  transition: filter 0.3s ease, opacity 0.3s ease;
}

.button-container:has(.githubbtn:hover) > :not(:hover) {
  filter: blur(2px);
  opacity: 0.8;
}


/* Button Styles */
.githubbtn1, .githubbtn2, .githubbtn3 {
  display: flex;
  align-items: center;
  font-family: var(--tooltip-font-family);
  background-color: rebeccapurple;
  color: white;
  padding: 3px;
  border-radius: 05px;
  font-size: 15px;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid rgb(0, 0, 0);
  margin-top: 10px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
}

.githubbtn1:hover, .githubbtn2:hover, .githubbtn3:hover {
  background-color: rgb(62, 26, 99);
}

.githubbtn1:active, .githubbtn2:active, .githubbtn3:active {
  transform: scale(0.9);
}

.githubimg {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  filter: invert(1) brightness(100%) saturate(0%);
}











/* Main tooltip container */
.custom-tooltip {
  background-color: var(--tooltip-bg-color) !important;
  color: var(--tooltip-text-color) !important;
  border: 1px solid var(--tooltip-border-color) !important;
  padding: var(--tooltip-padding) !important;
  font-family: var(--tooltip-font-family) !important;
  font-size: var(--tooltip-font-size-pc) !important;
  border-radius: var(--tooltip-border-radius) !important;
  max-width: var(--tooltip-max-width) !important;
  text-align: center;
  box-shadow: var(--tooltip-box-shadow) !important;
  font-weight: 200 !important;
  transition: all 0.3s ease;
}

.custom-tooltipnav {
  background-color: var(--tooltip-bg-colornav) !important;
  color: var(--tooltip-text-color) !important;
  border: 1px solid var(--tooltip-border-color) !important;
  padding: var(--tooltip-padding) !important;
  font-family: var(--tooltip-font-family) !important;
  font-size: var(--tooltip-font-size-pc) !important;
  border-radius: var(--tooltip-border-radius) !important;
  max-width: var(--tooltip-max-width) !important;
  text-align: center;
  box-shadow: var(--tooltip-box-shadow) !important;
  font-weight: 200 !important;
  transition: all 0.3s ease;
}

/* Ensure multiline tooltips render well */
.custom-tooltip[data-multiline='true'] {
  white-space: pre-wrap; /* Preserve line breaks */
}






















@media (max-width: 768px) {
  .projectstext {
    text-align: center;
  }

  .project-tags{
    justify-content: center;
    max-width: 100%;
  }


  .button-container:has(.githubbtn:hover) > :not(:hover) {
    filter: none;
    opacity: 1;
  }

  .githubbtn1:hover, .githubbtn2:hover, .githubbtn3:hover {
    background-color: rgb(62, 26, 99);
  }

  .project-tags:has(.label:hover) > :not(:hover) {
    filter: none;
    opacity: 1;
  }

  .project-flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10rem;
    min-height: 80vh;
    padding-block: 50px;
  }

  .project-card{
    width: 85vw;
    height: auto;
  }

  .projectstext {
    width: 80vw;
  }

  .projectsheader2{
    font-size: var(--projects-font-size-mobile);
    text-align: center;
  }

  .projectheader{
    font-size: 30px;
  }



  .githubbtn1, .githubbtn2, .githubbtn3 {
    padding: 8px;
    font-size: 12px;
    border: 1px solid rgb(0, 0, 0);
    margin-top: 10px;
    width: 120px;
  }
  

  .githubimg {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

}















@media (min-width: 769px) and (max-width: 1024px) {



  .button-container:has(.githubbtn:hover) > :not(:hover) {
    filter: none;
    opacity: 1;
  }

  .githubbtn1:hover, .githubbtn2:hover, .githubbtn3:hover {
    background-color: rgb(62, 26, 99);
  }

  .project-tags:has(.label:hover) > :not(:hover) {
    filter: none;
    opacity: 1;
  }
  
  .project-flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8rem;
    min-height: 80vh;
    padding-block: 50px;
  }

  .project-card{
    width: 65vw;
    height: auto;
  }

  .projectstext {
    min-width: 60vw;
  }

  .projectsheader2{
    font-size: var(--projects-font-size-mobile);
    text-align: center;
  }

  .projectheader{
    font-size: 30px;
  }



  .githubbtn1, .githubbtn2, .githubbtn3 {
    padding: 8px;
    font-size: 12px;
    border: 1px solid rgb(0, 0, 0);
    margin-top: 10px;
    width: 120px;
  }
  

  .githubimg {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }


}

















